import React, { useEffect, useRef, useState, useCallback } from 'react';
// import useRouter from 'use-react-router';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import ScaleLoader from 'react-spinners/ScaleLoader';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { useStyles } from '../styles/HTMLMarkupViewerStyles';
import ArticleIFrame from './ArticleIFrame.js';
import * as OV from '../api/OVConstants.js';
import UserPrefsSingleton from '../api/singletons/UserPrefsSingleton.js';
import PrintIcon from '../images/print_icon.svg';

import history from "history/browser";

export default function HTMLMarkupViewerComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const markup = useRef(null);
  // const { history } = useRouter();
  const [articleHtml, setArticleHtml] = useState(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [messageData, setMessageData] = useState('');
  const [shouldPrint, setShouldPrint] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof history.location.state !== 'undefined' 
    &&  history.location.state !== null
    &&  typeof history.location.state.markup !== 'undefined'
    &&  history.location.state.markup !== null
    ) {
      markup.current = history.location.state.markup;
      loadHTML(markup.current);
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const loadHTML = (clickedMarkup) => {
    axios.get(new URL(clickedMarkup.htmlURL))
    .then((response) => { 
      setArticleHtml(cleanupHTML(response.data));
    })
    .catch((error) => {
      console.error(`Error getting markup HTML: ${error.message}`);
    });
  };

  const cleanupHTML = (rawHTML) => {
    let retVal = rawHTML.replace(/<head>/, `<head><base href='${markup.current.htmlURL}'/>`);
    retVal = retVal.replace(/"\.\.\/tools\/lib\/miradobridge\.js"/, `'https://d2sa7z9rlbjc70.cloudfront.net/miradojsbridge.js'`);
    retVal = retVal.replace(/<\/head>/, `</head>
      <style type='text/css' media='print'>
        header, footer, aside, nav, form, iframe, .menu, .hero, .adslot, div.header, div.footertitle {
          display: none;
        }
      </style>
    `);
    return retVal;
  };

  const appBar = () => (
    <AppBar className={classes.appBar}>
      <Toolbar variant='dense'>
        {backButton()}
      </Toolbar>
    </AppBar>
  );

  const backButton = () => (
    <IconButton className={classes.iconButton} onClick={handleBackButtonOnClick}>
      <ArrowBackIos />
    </IconButton>
  );

  const loader = () => (
    <div className={classes.loaderContainer}>
      <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
    </div>
  );

  const toolbarOverlay = () => (
    <div className={classes.toolbarOverlay}>
      <img className={classes.toolbarButton} src={PrintIcon} onClick={handlePrintButtonOnClick}></img>
    </div>
  );

  const handleWindowResize = () => {
    forceUpdate();
  };

  const handleKeyDown = (event) => {
    const pKeyCode = 80;
    if ( event.keyCode === pKeyCode
      && (event.ctrlKey || event.metaKey)
      && !event.altKey
      && (!event.shiftKey || window.chrome || window.opera)
    ) {
      handlePrintButtonOnClick();

      event.preventDefault();
      if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      } else {
        event.stopPropagation();
      }
    }
  };

  const handleBackButtonOnClick = () => {
    delete history.location.state.markup;
    // history.replace(OV.RP_PUBVIEW, history.location.state);
    navigate(OV.RP_PUBVIEW, history.location.state);
  };

  const handlePrintButtonOnClick = () => {
    setShouldPrint(Date.now());
  };

  const iFrameAttributes = {
    srcDoc: articleHtml,
    width: '100%',
    height: '100%',
  };

  // parent received a message from iframe
  const onReceiveMessage = (event) => {
    try {
      console.log(`iFrame message received: ${JSON.stringify(event.data)}`);
      if (event.data.type === "webpackWarnings") { return; }

      const jsonEvent = JSON.parse(event.data);
      switch (jsonEvent.message) {
        case 'bridge_ready':
          setMessageData({ 'font_size': UserPrefsSingleton.fontSize() });
          break;

        case 'close_click':
          handleBackButtonOnClick();
          break;
        
        case 'fontsize_change':
          UserPrefsSingleton.setFontSize(jsonEvent.fontsize);
          break;
        
        case 'slideshow_click':
          const startSlideIndex = jsonEvent.slide_number - 1;
          history.location.state = {
              ...history.location.state
            , 'markup': markup.current
            , 'backPath': OV.RP_ARTICLE
            , 'startSlideIndex': startSlideIndex
          };
          history.push(OV.RP_SLIDESHOW, history.location.state);
          break;

        default:
          break;
      }
    } catch {
      console.error(`Could not parse message: ${event}`);
    }
  };

  return (
    <div className={classes.container}>
      {(typeof articleHtml === 'undefined' || articleHtml === null)
        ? <div>{appBar()}{loader()}</div>
        : <div className={classes.articleContainer}>
            {toolbarOverlay()}
            <ArticleIFrame
              attributes={iFrameAttributes}
              handleReceiveMessage={onReceiveMessage}
              postMessageData={messageData}
              shouldPrint={shouldPrint}
            ></ArticleIFrame>
          </div>
      }
    </div>
  );
};