import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
      width: '100%'
    , paddingBottom: '15px'
  }
  , loginButton: {
      width: '100%'
    , backgroundColor: theme.palette.primary.main
    , outline: 'none !important'
  }
  , loaderContainer: {
      display: 'flex'
    , justifyContent: 'center'
    , alignContent: 'center'
  }
  , taglineContainer: {
      textAlign:'left'
    , padding:'10px'
    , userSelect: 'none'
    , fontWeight: 'bold'
  }
  , statusContainer: {
    padding: '20px'
  }
  , textFieldLabel: {
    fontWeight: 'bold'
    , fontSize: '15px'
  }
  , input: {
    backgroundColor: 'white'
  }

}));