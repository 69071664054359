import * as OV from '../../api/OVConstants.js';
import PubParser from '../PubParser.js';

class PubSingleton {

    constructor() {
        if (!PubSingleton.instance) {
          PubSingleton.instance = this;
            this.data = {};
            this.loadPreviousPub();
        }
        return PubSingleton.instance;
      }
    
    loadPub(pub) {
      if (typeof pub === 'undefined' || pub === null) { return; }
      if (typeof pub.url === 'undefined' || pub.url === null) { return; }
      if (this.isThisPubLoaded(pub)) { return; }

      this.unloadPub();
      
      let pubParser = new PubParser();
      pubParser.parsePub(pub)
      .then((result) => {
        this.data.pubData = {...pub, ...result};
        localStorage.setItem(OV.LS_PUB, JSON.stringify(pub));

        let pubLoadedEvent = new CustomEvent('OVPubLoadedEvent');
        document.dispatchEvent(pubLoadedEvent);
      })
      .catch((error) => {
        console.error(`Failed to load pub ${error}`);
      });
    }

    loadPreviousPub() {
      const previousPubString = localStorage.getItem(OV.LS_PUB);
      if (typeof previousPubString !== 'undefined' 
        && previousPubString !== null 
        && !(previousPubString === "")) {
        try {
          const previousPub = JSON.parse(previousPubString);
          this.loadPub(previousPub);
        } catch(exception) {
          console.error(`Could not load previous pub: ${exception}`);
        }
      }
    }

    unloadPub() {
      this.data.pubData = null;
      localStorage.setItem(OV.LS_PUB, '');
    }

    isPubLoaded() {
      return (typeof this.data.pubData !== 'undefined' && this.data.pubData !== null);
    }

    isThisPubLoaded(pub) {
      if (!this.isPubLoaded()) { return false; }
      return (this.data.pubData.url === pub.url);
    }

    getName() {
      if (!this.isPubLoaded()) { return ''; }
      return this.data.pubData.name;
    }

    getIssueTag() {
      if (!this.isPubLoaded()) { return ''; }
      return this.data.pubData.issue_tag;
    }

    getIssueDay(){
      if (!this.isPubLoaded()) { return ''; }
      const pubDate = new Date(this.data.pubData.display_date);
      let issueDay = pubDate.getDay();
      return issueDay;
    }

    getDescription() {
      if (!this.isPubLoaded()) { return ''; }
      return this.data.pubData.description;
    }

    getPage(sectionIndex, pageIndex) {
      if (!this.isPubLoaded()) { return null; }
      
      return this.data.pubData.sectionList[sectionIndex].pageList[pageIndex];
    }

    getPages(sectionIndex) {
      let retVal = [];
      if (!this.isPubLoaded()) { return retVal; }

      return [...this.data.pubData.sectionList[sectionIndex].pageList];
    }

    getSpreads(sectionIndex) {
      let retVal = [];
      if (!this.isPubLoaded()) { return retVal; }

      let pageList = this.getPages(sectionIndex);
      if (pageList.length < 1) { return retVal; }

      const blankPageDimensions = {
          // pageWidth: pageList[0].pageWidth
          pageWidth: 0
        , pageHeight: pageList[0].pageHeight
        , thumbWidth: pageList[0].thumbWidth
        , thumbHeight: pageList[0].thumbHeight
      };


      let pageIndex = 0;
      while (pageList.length > 0) {
        let someSpread = {};
        if (pageIndex == 0) {
          someSpread.leftPage = this.getBlankPage(blankPageDimensions);
          someSpread.leftPage.pageIndex = pageIndex;
          pageIndex++;
          someSpread.rightPage = pageList.shift();
          someSpread.rightPage.pageIndex = pageIndex;
          someSpread.rightPage.displayName = `${pageIndex}`;
          pageIndex++;
        }
        else if (pageList.length > 1) {
          someSpread.leftPage = pageList.shift();
          someSpread.leftPage.pageIndex = pageIndex;
          someSpread.leftPage.displayName = `${pageIndex}`;
          pageIndex++;
          someSpread.rightPage = pageList.shift();
          someSpread.rightPage.pageIndex = pageIndex;
          someSpread.rightPage.displayName = `${pageIndex}`;
          pageIndex++;
        }
        else if (pageList.length == 1) {
          someSpread.leftPage = pageList.shift();
          someSpread.leftPage.pageIndex = pageIndex;
          someSpread.leftPage.displayName = `${pageIndex}`;
          pageIndex++;
          someSpread.rightPage = this.getBlankPage(blankPageDimensions);
          someSpread.rightPage.pageIndex = pageIndex;
          pageIndex++
        }
        retVal.push(someSpread);
      }

      return retVal;
    }

    getSectionPickerItems() {
      let retVal = [];
      if (!this.isPubLoaded()) { return retVal; }

      this.data.pubData.sectionList.map((someSection, index) => {
        let someItem = {};
        const firstSectionPage = someSection.pageList[0];
        someItem.url = firstSectionPage.thumbUrl;
        someItem.width = firstSectionPage.thumbWidth;
        someItem.height = firstSectionPage.thumbHeight;
        someItem.displayName = someSection.sectionName;
        retVal.push(someItem);
      });

      return retVal;
    }

    getBlankPage(dimensions) {
      return {
          isBlank: true
        , pageUrl: 'https://d2sa7z9rlbjc70.cloudfront.net/blank.pdf'
        , pageWidth: dimensions.pageWidth
        , pageHeight: dimensions.pageHeight
        , thumbWidth: dimensions.thumbWidth
        , thumbHeight: dimensions.thumbHeight
        , displayName: ''
      };
    }

    getHistoryOverride() {
      return this.data.historyOverride;
    }

    setHistoryOverride(updatedValue) {
      this.data.historyOverride = updatedValue;
    }
}

const instance = new PubSingleton();
Object.freeze(instance);

export default instance;