import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import useRouter from 'use-react-router';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Toolbar } from '@material-ui/core';
import { MenuBook, Schedule, Settings } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { useStyles } from '../styles/MainNavStyles';
import { AccountContext } from '../providers/AccountProvider.js';
import { accountStatusSelector } from '../redux/selectors/AccountSelectors.js';
import TitleComponent from './TitleComponent.js';
import ShowcaseComponent from './ShowcaseComponent.js';
import ArchiveComponent from './ArchiveComponent.js';
import SettingsComponent from './SettingsComponent.js';
import CatPickerComponent from './CatPickerComponent.js';
import SpecialOfferComponent from './SpecialOfferComponent.js';
import EntitiesSingleton from '../api/singletons/EntitiesSingleton.js';
import PubSingleton from '../api/singletons/PubSingleton.js';
import IPValidatorSingleton from '../api/singletons/IPValidatorSingleton.js';
import * as OV from '../api/OVConstants.js';
import MainIcon from '../Icons/Bubble.js'
import SettingsIcon from  '../Icons/Konto.js'
import history from "history/browser";

function TabPanel(props) {
  const { children, tabIndex, selectedTabIndex, ...other } = props;
  return ((tabIndex === selectedTabIndex) ? <>{children}</> : <></>);
}

TabPanel.propTypes = {
    children: PropTypes.node
  , tabIndex: PropTypes.number.isRequired
  , selectedTabIndex: PropTypes.number.isRequired
};

export default function MainNavComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const { history } = useRouter();
  // const { history } = useNavigate();
  const navigate = useNavigate();
  
  const accountStatus = useSelector(accountStatusSelector);
  const { checkIfPubIsRestricted } = useContext(AccountContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedCat, setSelectedCat] = useState({});
  const [selectedPub, setSelectedPub] = useState({});
  const [isCatPickerReadyToView, setIsCatPickerReadyToView] = useState(false);
  const [catPickerStartIndex, setCatPickerStartIndex] = useState(0);
  const [showcaseStartIndex, setShowcaseStartIndex] = useState(0);
  const [shouldShowSpecialOffer, setShouldShowSpecialOffer] = useState(accountStatus !== OV.STATUS_SUCCESS);
  // const [shouldShowSpecialOffer, setShouldShowSpecialOffer] = useState(false);
  const [isPubPremium, setIsPubPremium] = useState(false);
  const currentIssueTabIndex = 0;
  const archiveTabIndex = 1;
  const settingsTabIndex = 2;
  const [vis, setVis] = useState(true);
  const [showSettingsTab, setShowSettingsTab] = useState(false);
  // Get the current location.
  // console.log( `Pathname: ${history.location.pathname} State: ${history.location.state}`);

  useEffect(() => {
    if ( typeof history.location !== 'undefined'
      && history.location !== null
      && typeof history.location.state !== 'undefined' 
      && history.location.state !== null
      && typeof history.location.state.tabIndex !== 'undefined'
      && history.location.state.tabIndex !== null
    ) {
      // console.log( `SetTabIndex: ${history.location.state.tabIndex}`);
      setSelectedTabIndex(history.location.state.tabIndex);
    }

    // console.log(`Rendering in MainNavComponent Special Offer:${shouldShowSpecialOffer}`);

    EntitiesSingleton.fetchCurrentEntities()
    .then(() => {
      let initialCatIndex = 0;
      if ( typeof history.location !== 'undefined'
        && history.location !== null
        && typeof history.location.state !== 'undefined'
        && history.location.state !== null
        && typeof history.location.state.catIndex !== 'undefined'
        && history.location.state.catIndex !== null
      ) {
        initialCatIndex = Math.max(0, history.location.state.catIndex);
      }
      const initialCat = EntitiesSingleton.getCatPickerList()[initialCatIndex];
      setSelectedCat(initialCat);
      setCatPickerStartIndex(initialCatIndex);
      setIsCatPickerReadyToView(true);
      
      if ( typeof history.location !== 'undefined'
        && history.location !== null
        && typeof history.location.state !== 'undefined'
        && history.location.state !== null
        && typeof history.location.state.showcaseIndex !== 'undefined'
        && history.location.state.showcaseIndex !== null
      ) {
        setShowcaseStartIndex(Math.max(0, history.location.state.showcaseIndex));
      }
      else {
        selectFirstPubInCat(initialCat);
      }
    })
    .catch((error) => {
      console.error(`Error fetching current entities: ${error}`);
      setIsCatPickerReadyToView(true);
    });
  }, [shouldShowSpecialOffer]);

  history.listen(({ action, location }) => {
    console.log(
      `The current URL is ${location.pathname}${location.search}${location.hash}`
    );
    console.log(`The last navigation action was ${action}`);
    console.log(`The last history tabIndex: ${location.state.tabIndex}`);
  });

  const selectFirstPubInCat = (cat) => {
    const firstPub = getFirstPubInCat(cat);
    setSelectedPub(firstPub);
  };

  const getFirstPubInCat = (cat) => {
    let retVal = {};
    const showcaseCarouselItems = EntitiesSingleton.getShowcaseCarouselItemsForCat(cat);
    if (showcaseCarouselItems.length > 1) {
      retVal = showcaseCarouselItems[1];
    }
    else if (showcaseCarouselItems.length === 1) {
      retVal = showcaseCarouselItems[0];
    }
    return retVal;
  };

  const catPicker = () => (
    (selectedTabIndex === currentIssueTabIndex || selectedTabIndex === archiveTabIndex)
      ? (isCatPickerReadyToView)
        ? <CatPickerComponent
            sourceCatList={EntitiesSingleton.getCatPickerList()}
            selectedCat={selectedCat}
            onCatButtonClick={handleCatButtonOnClick}
            startIndex={catPickerStartIndex}
          ></CatPickerComponent>
        : catPickerLoader()
      : <></>
  );

  const catPickerLoader = () => {
    return (
      <div className={classes.catPickerLoaderContainer}>
        <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
      </div>
    );
  };

  const propsForTabAtIndex = (tabIndex) => {
    return {
      id: `tab-${tabIndex}`
    };
  };

  const handleCatButtonOnClick = (clickedCat) => {
    // PubSingleton.setHistoryOverride({ 'sectionIndex': 0, 'pageNumber': 1 });
    setSelectedCat(clickedCat);
    selectFirstPubInCat(clickedCat);
  };

  const handleTabIndexChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
    
    const firstPub = getFirstPubInCat(selectedCat);
    if (newValue === currentIssueTabIndex && firstPub.id !== selectedCat.id) {
      setSelectedPub(firstPub);
    }
  };

  const handleChangeTab = () => {
    setShowSettingsTab(!showSettingsTab)
    // if(showSettingsTab){
    //   setSelectedTabIndex(2);
    //   setShowSettingsTab(false);
    // }
    // else {
    //   setSelectedTabIndex(1);
    //   setShowSettingsTab(true);
    // }
    
  }

  const handleShowcaseItemOnClick = (clickedEntity, showcaseIndex) => {
    checkIfPubIsRestricted(clickedEntity)
    .then((result) => { 
      if (result.isPubRestricted && !IPValidatorSingleton.isIPValidated()) {
        requestAnimationFrame(() => {setShouldShowSpecialOffer(true); setIsPubPremium(true)});
      }
      else {
        PubSingleton.loadPub(clickedEntity);
        console.log(`MainNavComponent pushing OV.RP_PUBVIEW onto history stack`);
        history.push(OV.RP_PUBVIEW
          , { ...history.location.state
          , 'tabIndex': selectedTabIndex
          , 'catIndex': EntitiesSingleton.getIndexOfCat(selectedCat)
          , 'showcaseIndex': showcaseIndex
          }
        );
        navigate(OV.RP_PUBVIEW);
      }
    });
  };

  const handleArchivePubButtonOnClick = (clickedPub) => {
    checkIfPubIsRestricted(clickedPub)
    .then((result) => { 
      if (result.isPubRestricted && !IPValidatorSingleton.isIPValidated()) {
        requestAnimationFrame(() => {setShouldShowSpecialOffer(true); setIsPubPremium(true)});
      }
      else {
        PubSingleton.loadPub(clickedPub);
        history.push(OV.RP_PUBVIEW
          , { ...history.location.state
          , 'tabIndex': selectedTabIndex
          , 'catIndex': EntitiesSingleton.getIndexOfCat(selectedCat)
          }
        );
        navigate(OV.RP_PUBVIEW);
      }
    });

  };


  
  return (
    <div className={classes.root}>
      <Fab 
        alwaysShowTitle={true} 
        position={{ bottom: 0, right:0}}
        // mainButtonStyles={{ backgroundColor: '#0358a9', width: 55, borderRadius: 48 }}
        mainButtonStyles={{width: 55, height: 55}}
        event={false}
        icon={showSettingsTab ? <SettingsIcon width='55' height='55'/> : <MainIcon width='55' height='55'/>}
        onClick={() => handleChangeTab()}
      >

      </Fab>
      <TitleComponent title={theme.textAssets.title} />
      {/* <AppBar className={classes.appBar} position='fixed'>
        <Tabs
          classes={{ indicator: classes.tabIndicator }}
          value={selectedTabIndex} 
          onChange={handleTabIndexChange} 
          centered
        >
          <Tab 
            className={(selectedTabIndex === currentIssueTabIndex) ? classes.selectedTab : classes.tab} 
            label={<FormattedMessage id='main.tab.currentIssues' />}
            icon={<MenuBook />} 
            {...propsForTabAtIndex(currentIssueTabIndex)} 
          ></Tab>

          <Tab 
            className={(selectedTabIndex === archiveTabIndex) ? classes.selectedTab : classes.tab} 
            label={<FormattedMessage id='main.tab.archive' />}
            icon={<Schedule />}
            {...propsForTabAtIndex(archiveTabIndex)}
          ></Tab>

          <Tab 
            className={(selectedTabIndex === settingsTabIndex) ? classes.selectedTab : classes.tab}
            label={<FormattedMessage id='main.tab.settings' />}
            icon={<Settings />}
            {...propsForTabAtIndex(settingsTabIndex)}
          ></Tab>
        </Tabs>
      </AppBar> */}

      <Toolbar style={{ minHeight: 1 }}/> {/* NOTE: The empty toolbar is for spacing. */}
      {/* {catPicker()} */}

      <div className={classes.tabContentContainer}>
        {showSettingsTab ? 
          <div>
            <SettingsComponent />
          </div>
          :
          <div>
            {catPicker()}
            <ArchiveComponent 
            selectedCat={selectedCat}
            onPubButtonClick={handleArchivePubButtonOnClick}
            ></ArchiveComponent>
          </div>
        }
      </div>

      {/* <div className={classes.tabContentContainer}>
        <TabPanel tabIndex={currentIssueTabIndex} selectedTabIndex={selectedTabIndex}>
          <ShowcaseComponent
            selectedCat={selectedCat}
            startIndex={showcaseStartIndex}
            onShowcaseItemClick={handleShowcaseItemOnClick}
          ></ShowcaseComponent>
        </TabPanel>

        <TabPanel tabIndex={archiveTabIndex} selectedTabIndex={selectedTabIndex}>
          <ArchiveComponent 
            selectedCat={selectedCat}
            onPubButtonClick={handleArchivePubButtonOnClick}
          ></ArchiveComponent>
        </TabPanel>

        <TabPanel tabIndex={settingsTabIndex} selectedTabIndex={selectedTabIndex}>
          <SettingsComponent />
        </TabPanel>
      </div> */}

      <SpecialOfferComponent 
        isShowing={shouldShowSpecialOffer && isPubPremium}
        onClose={() => {setShouldShowSpecialOffer(false); setIsPubPremium(false)}}
      ></SpecialOfferComponent>
    </div>
  );
};