class InfoSingleton {

    constructor() {
      if (!InfoSingleton.instance) {
        InfoSingleton.instance = this;
        this.data = {};
      }
      return InfoSingleton.instance;
    }
    
    fetchInfo(url) {
        return new Promise((resolve, reject) => {
          fetch(url)
            .then((response) => response.json())
            .then((responseJSON) => this._validateResponseJSON(responseJSON))
            .then((responseJSON) => this._cleanupResponseJSON(responseJSON))
            .then((responseJSON) => { this.data.lastGoodResponse = responseJSON; })
            .then(() => resolve())
            .catch((error) => reject(error));
        });
      }

      getHTML(){
        return this._getHTMLFromResponseJSON(this.data.lastGoodResponse);
      }

      _validateResponseJSON(responseJSON) {
        if (typeof responseJSON === 'undefined' 
        ) {
          throw new Error('Bad Response');
        }
        
        return responseJSON;
      }
    
      _cleanupResponseJSON(responseJSON) {
        const stringifiedResponseJSON = JSON.stringify(responseJSON);
        const cleanedStringifiedResponseJSON = stringifiedResponseJSON.replace(/http\:\/\//g, 'https://');
        const cleanedResponseJSON = JSON.parse(cleanedStringifiedResponseJSON);
        return cleanedResponseJSON;
      }
      
      _getHTMLFromResponseJSON(responseJSON){
        return responseJSON.html;
      }
}
const instance = new InfoSingleton();
Object.freeze(instance);

export default instance;