import React, { useState, useEffect, useRef, useCallback, createRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import AliceCarousel from 'react-alice-carousel';
import Img from 'react-image';
import ScaleLoader from 'react-spinners/ScaleLoader';

import { useStyles } from '../styles/ShowcaseStyles';
import EntitiesSingleton from '../api/singletons/EntitiesSingleton.js';
import PremiumIcon from '../components/PremiumIconComponent';
import { AccountContext } from '../providers/AccountProvider';

export default function ShowcaseComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const shouldCancelShowcaseItemOnClick = useRef(false);
  const [showcaseEntities, setShowcaseEntities] = useState([]);
  const resizeTimeout = useRef(null);
  const shouldLockWheel = useRef(false);
  const carousel = createRef();
  const shouldIgnoreWheel = useRef(false);
  const wheelQuietTimeout = useRef(null);
  const previousWheelDelta = useRef(0);
  const { getBoolFromString } = useContext(AccountContext);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('wheel', handleDocumentWheel, { passive: false });
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('wheel', handleDocumentWheel);
    };
  }, []);

  useEffect(() => {
    setShowcaseEntities(EntitiesSingleton.getShowcaseCarouselItemsForCat(props.selectedCat));
  }, [props.selectedCat]);

  const showcaseItems = () => {
    let retVal = [];
    for (const someEntity of showcaseEntities) {
      const someIndex = retVal.length;
      retVal.push(
        <div className={classes.showcaseImgContainer}>
          <div style={{ height: theme.showcasePicker.reflectionHeight }}></div>
          <Img
            className={classes.showcaseImg}
            src={bookshelfImagesForEntity(someEntity)}
            loader={loader()}
            onDragStart={handleImgOnDragStart}
            onMouseDown={handleShowcaseItemOnMouseDown}
            onMouseMove={handleShowcaseItemOnMouseMove}
            onMouseUp={handleShowcaseItemOnMouseUp.bind(this, someEntity, someIndex)}
          ></Img>
          {(getBoolFromString(someEntity.premium)) 
            ? <PremiumIcon width='160px' height='50px'></PremiumIcon>
            : <></>
          }
        </div>
      );
    }
    return retVal;
  };

  const bookshelfImagesForEntity = (entity) => {
    let retVal = [];
    retVal.push(entity.thumbnail.replace(/bookshelf.png/, 'bookshelf@4x.png'))
    retVal.push(entity.thumbnail.replace(/bookshelf.png/, 'bookshelf@3x.png'))
    retVal.push(entity.thumbnail.replace(/bookshelf.png/, 'bookshelf@2x.png'))
    retVal.push(entity.thumbnail);
    return retVal;
  };

  const loader = () => (
    <div className={classes.loaderContainer}>
      <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
    </div>
  );

  const showcaseCarousel = () => {
    const items = showcaseItems();
    return (
      <div
        id='carouselContainer'
        className={classes.carouselContainer}
        style={{
            height: `calc(100% - ${topAreaHeight()}px)`
          , top: `${topAreaHeight()}px}`
        }}
        onWheel={handleCarouselContainerOnWheel}
        onMouseEnter={handleCarouselContainerOnMouseEnter}
        onMouseLeave={handleCarouselContainerOnMouseLeave}
      >
        <AliceCarousel
          ref={carousel}
          startIndex={props.startIndex}
          mouseTrackingEnabled
          dotsDisabled
          buttonsDisabled
          preservePosition
          infinite={items.length > 1}
          items={items}
          responsive={showcaseCarouselResponsiveLayout()}
        ></AliceCarousel>
      </div>
    );
  }

  const showcaseCarouselResponsiveLayout = () => {
    let retVal = {};
    let runningWidth = 0;
    let runningIndex = 0;
    const maxThumbWidth = parseInt(theme.showcasePicker.maxThumbWidth.replace(/px/, ''));
    showcaseEntities.forEach((someEntity) => {
      retVal[runningWidth] = { items: runningIndex };
      let delta = maxThumbWidth;
      delta *= Math.min(1, itemWidthDelta());
      runningWidth += delta;
      runningIndex++;
    });
    retVal[runningWidth] = { items: runningIndex };
    return retVal;
  };

  const topAreaHeight = () => {
    const maxCatPickerThumbHeight = parseInt(theme.catPicker.maxThumbHeight.replace(/px/, ''));
    return maxCatPickerThumbHeight + 120;
  };

  const itemWidthDelta = () => {
    const maxThumbHeight = parseInt(theme.showcasePicker.maxThumbHeight.replace(/px/, ''));
    const reflectionHeight = parseInt(theme.showcasePicker.reflectionHeight.replace(/px/, ''));
    return ((window.innerHeight - topAreaHeight() - reflectionHeight - 20) / maxThumbHeight) // NOTE: 20 = padding
  };

  const handleResize = (event) => {
    clearTimeout(resizeTimeout.current);
    resizeTimeout.current = setTimeout(forceUpdate, 250);
  };

  const handleImgOnDragStart = (event) => {
    event.preventDefault();
  };
  
  const handleShowcaseItemOnMouseDown = () => {
    shouldCancelShowcaseItemOnClick.current = false;
  };

  const handleShowcaseItemOnMouseMove = () => {
    shouldCancelShowcaseItemOnClick.current = true;
  };

  const handleShowcaseItemOnMouseUp = (clickedEntity, showcaseIndex) => {
    if (!shouldCancelShowcaseItemOnClick.current) {
      props.onShowcaseItemClick(clickedEntity, showcaseIndex);
    }
  };

  const handleCarouselContainerOnWheel = (event) => {
    const delta = (event.deltaY === 0) ? event.deltaX : event.deltaY;
    const didChangeDirections = (
         (delta > 0 && previousWheelDelta.current < 0) 
      || (delta < 0 && previousWheelDelta.current > 0)
    );
    if (didChangeDirections || Math.abs(delta - previousWheelDelta.current) > 10) {
      shouldIgnoreWheel.current = false;
      clearTimeout(wheelQuietTimeout.current);
    }
    previousWheelDelta.current = delta;

    if (shouldIgnoreWheel.current) {
      clearTimeout(wheelQuietTimeout.current);
      wheelQuietTimeout.current = setTimeout(() => {
        shouldIgnoreWheel.current = false;
      }, 50);
      return;
    }

    shouldIgnoreWheel.current = true;
    wheelQuietTimeout.current = setTimeout(() => {
      shouldIgnoreWheel.current = false;
    }, 50);

    if (delta > 0) {
      carousel.current.slidePrev();
    }
    else if (delta < 0) {
      carousel.current.slideNext();
    }
  };

  const handleDocumentWheel = (event) => {
    if (shouldLockWheel.current) {
      event.preventDefault();
    }
  };

  const handleCarouselContainerOnMouseEnter = () => {
    shouldLockWheel.current = true;
  };

  const handleCarouselContainerOnMouseLeave = () => {
    shouldLockWheel.current = false;
  };

  return (
    <div style={{ height: '100%' }}>
      {showcaseCarousel()}
    </div>
  );
};

ShowcaseComponent.propTypes = {
    selectedCat: PropTypes.any.isRequired
  , startIndex: PropTypes.number
  , onShowcaseItemClick: PropTypes.func
};