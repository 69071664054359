import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '../styles/PremiumIconStyles';
import SNPlusIcon from '../images/sn_plus.svg';

export default function PremiumIconComponent(props) {
  const classes = useStyles();

  const handleImgOnDragStart = (event) => {
    event.preventDefault();
  };

  return (
    <div 
      className={classes.premiumIconContainer}
      style={{ width: props.width, height: props.height }}
    >
      <img
        className={classes.snIcon}
        src={SNPlusIcon} 
        onDragStart={handleImgOnDragStart}
      ></img>
    </div>
  );
};

PremiumIconComponent.propTypes = {
    width: PropTypes.string.isRequired
  , height: PropTypes.string.isRequired
};