import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar, Slide, ClickAwayListener, IconButton } from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import {Img} from 'react-image';
import { FormattedMessage } from 'react-intl';
import Fade from '@material-ui/core/Fade';

import { useStyles } from '../styles/SettingsAccountSytles';
import LogoImage from '../images/sn_splashscreen.png';
import PersonImage from '../images/person.svg';
import AccountComponent from '../components/AccountComponent.js';



export default function SettingsAccountComponent(props) {

  const classes = useStyles();

  const onCloseButtonClick = () => {
    // setOpen(false);
    // props.onClose();
    
  };

  const onClickAway = () => {
    // setOpen(false);
    // props.onClose();
  };

  return (
        <Img
          className={classes.personIcon}
          src={PersonImage}
          container={(img) => (
            <div className={classes.accountContent}>
              <ClickAwayListener onClickAway={onClickAway}>
                {/* <Snackbar
                  TransitionComponent={Slide}
                  open={true}
                  anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
                > */}
                  <Paper className={classes.paper} elevation={3}>
                    {/* <IconButton className={classes.closeButton} onClick={onCloseButtonClick}>
                      <HighlightOffRounded
                        fontSize='large'
                      >
                      </HighlightOffRounded>
                    </IconButton> */}
                    {img}
                    <div className={classes.personText}><FormattedMessage id='account.sign.in' /></div>
                    <AccountComponent isLongVersion={false}/>
                  </Paper>
                {/* </Snackbar> */}
              </ClickAwayListener>
            </div>
          )}
        >
        </Img>
    );

};

// SettingsAccountComponent.propTypes = {
//     isShowing: PropTypes.bool.isRequired
//   , onClose: PropTypes.func.isRequired
// };