import aesjs from 'aes-js';
import xml2js from 'xml2js';
import axios from 'axios';
// import path from 'path';
const path = require('path')

class PubParser {

  parsePub(pub) {
    return new Promise((resolve, reject) => {
      this._readPubIndexFile(new URL(pub.webapp_url), resolve, reject);
    });
  }

  // "private" methods

  _readPubIndexFile(pubURL, resolve, reject) {
    const baseName = path.basename(pubURL.pathname);
    const baseURL = pubURL.toString().replace(baseName, '');
    const indexURL = pubURL.toString().replace(baseName, 'index.xml');

    const getConfig = {
      onDownloadProgress: this._downloadProgress
    };

    axios.get(indexURL, getConfig)
      .then((response) => this._xmlToJSON(response.data))
      .then((pubIndexJSON) => this._parsePubData({ pubIndexJSON: pubIndexJSON, baseURL: baseURL }))
      .then((pubData) => resolve(pubData))
      .catch((error) => reject(error));
  }

  _downloadProgress(progressEvent) {
    const loaded = progressEvent.loaded;
    const total = progressEvent.total;
    const progress = (loaded / total) * 100;
    const eventDetails = { detail: { progress: progress } };
    let pubLoadProgressEvent = new CustomEvent('OVPubLoadProgressEvent', eventDetails);

    document.dispatchEvent(pubLoadProgressEvent);
  }

  _decryptArrayBuffer(encryptedArrayBuffer) {
    let scrambledKey = '46-3E-3F-DD-42-72-BC-35-FF-40-ED-EE-C7-F6-73-16-A2-13-DF-47-03-D1-BA-76-92-8A-0E-80-33-9B-B6-D9-E1-D3';
    let scrambledIV = 'F8-E8-17-E3-99-29-F3-00-61-26-C4-E7-56-73-67-DB-58-63';

    let unscrambledKey = this._unscramble(scrambledKey);
    let unscrambledIV = this._unscramble(scrambledIV);

    let encodedKey = this._hexArrayToByteArray(unscrambledKey);
    let encodedIV = this._hexArrayToByteArray(unscrambledIV);

    let encryptedBytes = new Uint8Array(encryptedArrayBuffer);
    let paddedAndEncryptedBytes = aesjs.padding.pkcs7.pad(encryptedBytes);
    let aesCbc = new aesjs.ModeOfOperation.cbc(encodedKey, encodedIV);
    let decryptedBytes = aesCbc.decrypt(paddedAndEncryptedBytes);
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  }

  _unscramble(scrambledString) {
    let retVal = scrambledString.split('-');
    retVal.shift();
    retVal.pop();

    const tempPiece = retVal[0];
    retVal[0] = retVal[retVal.length - 1];
    retVal[retVal.length - 1] = tempPiece;

    retVal.reverse();

    return retVal;
  }

  _hexArrayToByteArray(hexArray) {
    let retVal = new Uint8Array(hexArray.length);
    let index = 0;
    hexArray.forEach((someHexPiece) => {
      retVal[index] = aesjs.utils.hex.toBytes(someHexPiece);
      index++;
    });
    return retVal;
  }

  _xmlToJSON(xmlStr) {
    let xml2jsParser = new xml2js.Parser();
    return xml2jsParser.parseStringPromise(xmlStr)
  }

  _parsePubData({ pubIndexJSON: pubIndexJSON, baseURL: baseURL}) {
    let pubData = {};
    const assetDict = this._parseAssetDict({ pubIndexJSON: pubIndexJSON });
    pubData.sectionList = this._parseSectionList({ 
        pubIndexJSON: pubIndexJSON
      , assetDict: assetDict
      , baseURL: baseURL
    });
    return pubData;
  }

  _parseAssetDict({ pubIndexJSON: pubIndexJSON }) {
    let assetDict = {};
    Array.from(pubIndexJSON.Publication.Assets[0].AssetGroup[0].Asset).forEach((someAsset) => {
      assetDict[someAsset.$.id] = someAsset;
    });
    return assetDict;
  }

  _parseSectionList({ 
      pubIndexJSON: pubIndexJSON
    , assetDict: assetDict
    , baseURL: baseURL 
  }) {
    let sectionList = [];
    Array.from(pubIndexJSON.Publication.Sections[0].Section).forEach((someSection) => {
      let someSectionData = {};
      someSectionData.sectionName = someSection.name[0];
      someSectionData.pageList = this._parsePageList({
          section: someSection
        , pubIndexJSON: pubIndexJSON
        , assetDict: assetDict
        , baseURL: baseURL
      });
      sectionList.push(someSectionData);
    });
    return sectionList;
  }

  _parsePageList({
      section = {}
    , pubIndexJSON = {}
    , assetDict = {}
    , baseURL = ''
  }) {
    let pageList = [];
    Array.from(section.Pages[0].Page).forEach((someSectionPage) => {
      let pageData = {};
      pageData.sourcePageRef = someSectionPage.SourcePageRef;
      pageData.markupList = this._parseMarkupList({
          sectionPage: someSectionPage
        , assetDict: assetDict
        , baseURL: baseURL
      });
      pageData = {
        ...pageData
        , ...this._parseSourcePageData({
            sourcePageRef: pageData.sourcePageRef
          , markupList: pageData.markupList
          , pubIndexJSON: pubIndexJSON
          , baseURL: baseURL
        })
      };
      pageList.push(pageData);
    });
    return pageList;
  }

  _parseMarkupList({
      sectionPage = {}
    , assetDict = {}
    , baseURL = ''
  }) {
    let markupList = [];
    if (sectionPage.Markups != "") {
      Array.from(sectionPage.Markups[0].Markup).some((someMarkup) => {
        let markupData = {};
        markupData.id = someMarkup.$.id;
        markupData.x = someMarkup.x;
        markupData.y = someMarkup.y;
        markupData.width = someMarkup.width;
        markupData.height = someMarkup.height;
        const assetRef = someMarkup.Assetref;
        const someAsset = assetDict[assetRef];
        markupData.type = someAsset.type;
        markupData.value = someAsset.value;
        if (someAsset.type[0] === 'html-bundle') {
          markupData.htmlURL = `${baseURL}${markupData.value}/${someMarkup.DesktopRelativeHtmlFilePath}`;

          // NOTE: HTML Markups sometimes contain slideshows.
          const slideshowAssetRef = someMarkup.SlideShowClickAssetRef
          if (typeof slideshowAssetRef !== 'undefined' && slideshowAssetRef !== null) {
            const someSlideshowAsset = assetDict[slideshowAssetRef];
            markupData = { ...markupData, ...this._parseSlideshowMarkupData({
                slideshowAsset: someSlideshowAsset
              , assetDict: assetDict
              , baseURL: baseURL
            })};
          }
        }
        else if (someAsset.type[0] === 'slide-show') {
          markupData = { ...markupData, ...this._parseSlideshowMarkupData({
              slideshowAsset: someAsset
            , assetDict: assetDict
            , baseURL: baseURL
          })};
        }

        markupList.push(markupData);
      });
    }
    return markupList;
  }

  _parseSlideshowMarkupData({
      slideshowAsset = {}
    , assetDict = {}
    , baseURL = ''
  }) {
    let markupData = {};
    let slideshowItems = [];
    Array.from(slideshowAsset.slideShowItems[0].slideShowItem).forEach((someSlideshowItem) => {
      let slideshowItemData = {};
      const slideshowItemAsset = assetDict[someSlideshowItem.assetRef];
      slideshowItemData.url = `${baseURL}${slideshowItemAsset.value}`;
      slideshowItemData.captionDescription = someSlideshowItem.captionDescription[0];
      slideshowItemData.captionCopyright = someSlideshowItem.captionCopyright[0];
      slideshowItems.push(slideshowItemData);
    });
    markupData.slideshowItems = slideshowItems;
    return markupData;
  }

  _parseSourcePageData({
      sourcePageRef = ''
    , markupList = []
    , pubIndexJSON = {}
    , baseURL = ''
  }) {
    let pageData = {};
    Array.from(pubIndexJSON.Publication.SourcePages[0].SourcePage).some((someSourcePage) => {
      if (someSourcePage.$.id == sourcePageRef) {
        pageData.pdfName = someSourcePage.pdf[0];
        const pageName = pageData.pdfName.replace(/\.pdf$/, '');
        pageData.pageUrl = `${baseURL}${pageName}/${pageData.pdfName}`;
        pageData.pageWidth = parseInt(someSourcePage.jpg[0].$.width);
        pageData.pageHeight = parseInt(someSourcePage.jpg[0].$.height);
        const thumbName = someSourcePage.thumbnail[0]._;
        pageData.thumbUrl = `${baseURL}${pageName}/${thumbName}`;
        pageData.thumbWidth = parseInt(someSourcePage.thumbnail[0].$.width);
        pageData.thumbHeight = parseInt(someSourcePage.thumbnail[0].$.height);
        pageData.preview72dpiUrl = `${baseURL}${pageName}/${pageName}.preview_72dpi.png`;
        pageData.preview144dpiUrl = `${baseURL}${pageName}/${pageName}.preview_144dpi.png`;
        pageData.preview200dpiUrl = `${baseURL}${pageName}/${pageName}.preview_200dpi.png`;
        pageData.preview288dpiUrl = `${baseURL}${pageName}/${pageName}.preview_288dpi.png`;

        markupList.forEach((someMarkup) => {
          someMarkup.x = someMarkup.x / pageData.pageWidth;
          someMarkup.y = someMarkup.y / pageData.pageHeight;
          someMarkup.width = someMarkup.width / pageData.pageWidth;
          someMarkup.height = someMarkup.height / pageData.pageHeight;
        });

        return true;
      }
      else {
        return false;
      }
    });
    return pageData;
  }
}

export default PubParser;