import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useStyles } from '../styles/LazyPubViewerStyles';
export default function LazyPubView(props) {
  const classes = useStyles();
  const PubViewerComponent = lazy(() => import('./PubViewerComponent.js'));

  const loader = () => (
    <div className={classes.loaderContainer}>
      <FormattedMessage id='reader.pdfLibLoader' />
    </div>
  );

  return (
    <Suspense fallback={loader()}>
      <PubViewerComponent isPreview={props.isPreview} onPreviewPageClick={props.onPreviewPageClick} />
    </Suspense>
  );
};

LazyPubView.propTypes = {
    isPreview: PropTypes.bool.isRequired
  , onPreviewPageClick: PropTypes.func
};

LazyPubView.defaultProps = {
  isPreview: false
};