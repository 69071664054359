import React, { useState, useRef, useEffect, useCallback } from 'react'
import IframeResizer from 'iframe-resizer-react'
import InfoSingleton from '../api/singletons/InfoSingleton.js';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../styles/JsonHtmlStyles';

export default function TsandCsComponent(props) {
  const iframeRef = useRef(null)
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const classes = useStyles();
  const theme = useTheme();
  const jsonHtmlUrl = "https://www.sn.at/service/wir-ueber-uns/agb-allgemeine-geschaeftsbedingungen-6403009/json";
  const [isJsonHtmlReadyToView, setIsJsonHtmlReadyToView] = useState(false);
  const [jsonHtmlContent, setJsonHtmlContent] = useState(`<html> </html>`);

  React.useEffect(() => {
    console.log(`initial window innerHeight: ${window.innerHeight}`);
    console.log(`initial window innerWidth: ${window.innerWidth}`);
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
});

useEffect(() => {
    
  InfoSingleton.fetchInfo(jsonHtmlUrl)
  .then(() => {
    var tmp = InfoSingleton.getHTML();
    var jsonHtml = tmp[0];
    setJsonHtmlContent(
      `<html>
      </head>

       <body>${jsonHtml}</body>
      </html>
      `
    );

    setIsJsonHtmlReadyToView(true);
  })

}, []);

const updateWidthAndHeight = () => {
    console.log(`window innerHeight: ${window.innerHeight}`);
    console.log(`window innerWidth: ${window.innerWidth}`);
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
};

const loader = () => (
  <div className={classes.loaderContainer}>
    <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
  </div>
);

 const jsonHtmlView = () => (
      <IframeResizer
          forwardRef={iframeRef}
          // heightCalculationMethod='max'
          // minHeight="700"
          scrolling="true"
          inPageLinks="true"
          // log
          height={height}
          width={width}
          srcDoc = {jsonHtmlContent}
          style={{ width: '1px', minWidth: '100%', background: '#FFFFFF' }}
        />
)

  return (isJsonHtmlReadyToView ? jsonHtmlView() : loader());
};