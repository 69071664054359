import * as OV from '../OVConstants.js';

class UserPrefsSingleton {
  constructor() {
    if (!UserPrefsSingleton.instance) {
      UserPrefsSingleton.instance = this;
    }
    return UserPrefsSingleton.instance;
  }

  currentLocale() {
    return localStorage.getItem(OV.LS_LOCALE);
  }

  setCurrentlLocale(updatedLocale) {
    localStorage.setItem(OV.LS_LOCALE, updatedLocale);
  }

  archiveDate() {
    let rawArchiveTime = localStorage.getItem(OV.LS_ARCHIVE_TIME)
    // console.log(`*********** Raw date from localStorage ${rawArchiveTime} **********`);
    // NOTE: Watch out for string with 'null' value.
    if (typeof rawArchiveTime == 'undefined' || rawArchiveTime == null || rawArchiveTime == 'null') {
      // console.log(`*********** Bad raw date from localStorage ${rawArchiveTime} returning null **********`);
      return null;
    }
    else {
      // let parsedDate = parseInt(rawArchiveTime);
      // console.log(`*********** Parsed int epoch time from localStorage ${parsedDate} **********`);
      return new Date(parseInt(rawArchiveTime));
    }
  }

  setArchiveDate(updatedDate) {
    let updatedTime = null;
    if (typeof updatedDate != 'undefined' && updatedDate != null) {
      updatedTime = updatedDate.getTime();
    }
    localStorage.setItem(OV.LS_ARCHIVE_TIME, updatedTime);
  }

  isSinglePage() {
    return JSON.parse(localStorage.getItem(OV.LS_SINGLEPAGE) || false);
  }

  setIsSinglePage(updatedValue) {
    localStorage.setItem(OV.LS_SINGLEPAGE, updatedValue);
  }

  isFitWidth() {
    return JSON.parse(localStorage.getItem(OV.LS_FITWIDTH) || false);
  }

  setIsFitWidth(updatedValue) {
    localStorage.setItem(OV.LS_FITWIDTH, updatedValue);
  }

  fontSize() {
    return localStorage.getItem(OV.LS_FONTSIZE) || 'medium';
  }

  setFontSize(updatedValue) {
    localStorage.setItem(OV.LS_FONTSIZE, updatedValue);
  }

  isCaptioning() {
    return JSON.parse(localStorage.getItem(OV.LS_CAPTIONS) || true);
  }

  setIsCaptioning(updatedValue) {
    localStorage.setItem(OV.LS_CAPTIONS, updatedValue);
  }
}

const instance = new UserPrefsSingleton();
Object.freeze(instance);

export default instance;
