import React, { useState, useRef, useEffect, useCallback } from 'react'
// import Iframe from 'react-iframe'
import IframeResizer from 'iframe-resizer-react'
import InfoSingleton from '../api/singletons/InfoSingleton.js';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../styles/JsonHtmlStyles';

export default function PrivacyComponent(props) {
  const iframeRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const jsonHtmlUrl = "https://www.sn.at/service/wir-ueber-uns/datenschutz-18846130/json";
  const [isJsonHtmlReadyToView, setIsJsonHtmlReadyToView] = useState(false);
  const [jsonHtmlContent, setJsonHtmlContent] = useState(`<html> </html>`);

  useEffect(() => {
    // console.log(`initial window innerHeight: ${window.innerHeight}`);
    // console.log(`initial window innerWidth: ${window.innerWidth}`);
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  useEffect(() => {
    
    InfoSingleton.fetchInfo(jsonHtmlUrl)
    .then(() => {
      var tmp = InfoSingleton.getHTML();
      var jsonHtml = tmp[0];
      setJsonHtmlContent(
        `<html>
        </head>

         <body>${jsonHtml}</body>
        </html>
        `
      );

      setIsJsonHtmlReadyToView(true);
    })

  }, []);

  const updateWidthAndHeight = () => {
    // console.log(`window innerHeight: ${window.innerHeight}`);
    // console.log(`window innerWidth: ${window.innerWidth}`);
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
 };

 const loader = () => (
  <div className={classes.loaderContainer}>
    <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
  </div>
);

 const jsonHtmlView = () => (
      <IframeResizer
          forwardRef={iframeRef}
          // heightCalculationMethod='max'
          // minHeight="700"
          scrolling="true"
          inPageLinks="true"
          // log
          height={height}
          width={width}
          srcDoc = {jsonHtmlContent}
          style={{ width: '1px', minWidth: '100%', background: '#FFFFFF', pointerEvents: 'none' }}
        />
)

  return (isJsonHtmlReadyToView ? jsonHtmlView() : loader());
};