import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

export default function TitleComponent(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired
};