import React, { useState, useEffect, useRef } from 'react';
import { Toolbar, ImageList, AppBar, InputBase } from '@material-ui/core';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/core/styles';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import {Img} from 'react-image';

import { useStyles } from '../styles/ArchiveStyles';
import StorefrontItemComponent from './StorefrontItemComponent.js';
import EntitiesSingleton from '../api/singletons/EntitiesSingleton.js';
import UserPrefsSingleton from '../api/singletons/UserPrefsSingleton.js';

export default function ArchiveComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [isArchiveReadyToView, setIsArchiveReadyToView] = useState(false);
  const [pubList, setPubList] = useState([]);
  const activeDates = useRef([]);
  const [selectedDate, setSelectedDate] = useState(UserPrefsSingleton.archiveDate());
  var activeCatKey = null;
  var activeCatCount = 0;

  // setIsArchiveReadyToView(false);
  // console.log(`*********** initial selcteded date ${selectedDate} **********`);
  // if(selectedDate === null){
  //   console.log(`(*********** The selectedDate is null ***********`)
  // }
  // if(selectedDate === 'Invalid Date') {
  //   console.log(`(*********** The selectedDate is Invalid Date ***********`)
  // }
  useEffect(() => {
    console.log(`Archive Selected Category ********** ${props.selectedCat.name}`);
    
    EntitiesSingleton.fetchArchiveEntities()
      .then(() => {
        // console.log(`*********** Archive Entities fetched **********`);
        const tmpList = EntitiesSingleton.getArchlistWithContainer(props.selectedCat)
        // console.log(`*********** Archlist with containeer gotten **********`);
        setPubList(tmpList);

        // Object.keys(tmpList).forEach(element => {
        //   if(!(element === 'undefined' || element === null)) {
        //     var eid = Number(element);
        //     var catStr = element;
        //     if(!Number.isNaN(eid)) {
        //       var tmp = EntitiesSingleton.getCatFromEntityId(eid);
        //       catStr = tmp.name;
        //     }
        //     catListCount[catStr] = 0;
        //     console.log(`catListCount[${catStr}] count: ${catListCount[catStr]}}`);
        //   }
        // })
        
        setIsArchiveReadyToView(true);
        // console.log(`*********** Archive set ready **********`);
      })
      .catch((error) => {
        console.log(`Error: Failed to fetch archive list: ${error}`);
        setIsArchiveReadyToView(true);
      });
  }, [props.selectedCat]);

  useEffect(() => {
    // console.log(`*********** useEffect to calculate active months **********`);
    calculateActiveMonths();
    // console.log(`*********** Active months calculated **********`);
    setupSelectedDate();
    // console.log(`*********** Seleted date setup **********`);
  }, [pubList]);

  const calculateActiveMonths = () => {
    // console.log(`*********** Starting to calculate active months **********`);
    let updatedList = [];
    for( const key in pubList) {
      var pList = pubList[key];
      for (const somePub of pList) {
        try {
          var tmpDateStr = somePub.display_date;
          const someRawDisplayDate = tmpDateStr.replace(/-/g, '/');
          // console.log(`*********** calculateActiveMonths someRawDisplayDate: ${someRawDisplayDate} `);
          const someDisplayDate = new Date(someRawDisplayDate);
          const someYear = someDisplayDate.getFullYear();
          const someMonth = someDisplayDate.getMonth();
          // console.log(`*********** calculateActiveMonths year: ${someYear} month: ${someMonth} **********`);
          const someActiveDate = new Date(someYear, someMonth);
          // console.log(`*********** calculateActiveMonths someActiveDate: ${someActiveDate} **********`);
          if (!updatedList.includes(+someActiveDate)) {
            // console.log(`*********** calculateActiveMonths added ot updatedList: ${someActiveDate} count: ${updatedList.length} **********`);
            updatedList.push(+someActiveDate);
          }
        } catch (error) {
          console.error(`ERROR: Could not parse date: ${someRawDisplayDate}, ${error}`);
        }
      }
    }

    activeDates.current = updatedList;
    // console.log(`*********** Finished calculating ${updatedList.length} active months **********`);
  };

  const setupSelectedDate = () => {
    // console.log(`*********** setupSelectedDate for ${selectedDate} **********`);
    if ( activeDates.current.length > 0
      && (typeof selectedDate === 'undefined' || selectedDate === null)
    ) {
      // console.log(`*********** setupSelectedDate try find most recent activeDates count ${activeDates.current.length} **********`);
      // var tmpDate = activeDates.current.sort()[activeDates.current.length - 1];
      // console.log(`*********** setupSelectedDate temp active date ${tmpDate} **********`);

      const mostRecentActiveDate = new Date(
        activeDates.current.sort()[activeDates.current.length - 1]
      );
      // console.log(`*********** setupSelectedDate the most recent active date ${mostRecentActiveDate} **********`);
      setSelectedDate(mostRecentActiveDate);
    }
  };

  const isActivePub = (somePub) => {
    try {
      if (typeof somePub === 'undefined' || somePub === null) { return false; }
      var tmpDateStr = somePub.display_date;
      const somePubDisplayDate = tmpDateStr.replace(/-/g, '/');
      if (typeof somePubDisplayDate === 'undefined' || somePubDisplayDate == null) { return false; }
      const someDate = new Date(somePubDisplayDate);
      const someYear = someDate.getFullYear();
      const someMonth = someDate.getMonth(); 
      // const someDay = 1; 
      const someActiveDate = new Date(someYear, someMonth);
      // const someActiveDate = new Date(someMonth, someDay, someYear);
      
      // NOTE: Watch out, if selectedDate is null, then return false other every pub
      // url is processed by the StorefrontItemComponent.
      if (typeof selectedDate === 'undefined' || selectedDate === null) { 
        // console.log(`The selected date is null`);
        return false; 
      }
      const selectedYear = selectedDate.getFullYear();
      const selectedMonth = selectedDate.getMonth();
      // const selectedDay = 1;
      const selectedActiveDate = new Date(selectedYear, selectedMonth);
      // const selectedActiveDate = new Date(selectedMonth, selectedDay, selectedYear);
      var result = +someActiveDate === +selectedActiveDate;
      // console.log(`The date filter is: ${result}`);
      if(result) {
        activeCatCount++;
        // console.log(`ActiveCat ${activeCatKey} count: ${activeCatCount}`);
      }
      return (+someActiveDate === +selectedActiveDate);
    } catch (error) {
      console.error(`ERROR: Bad date comparison ${somePubDisplayDate} vs ${selectedDate}: ${error}`);
      return false;
    }
  };

  const isRecentPub = (somePub) => {
    try {
      if (typeof somePub === 'undefined' || somePub === null) { return false; }
      if(activeCatKey ==="genPubs"){ return false; }

      // console.log(`ActiveCat ${activeCatKey} count: ${activeCatCount}`);
      activeCatCount++;
      return (activeCatCount < 2);
    } catch (error) {
      console.error(`ERROR: in isRecentPub: ${error}`);
      return false;
    }
  };

  const loader = () => (
    <div className={classes.loaderContainer}>
      <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
    </div>
  );

  const searchBar = () => (
    <div>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div className={classes.searchContainer}>
          <div className={classes.searchIconContainer}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={intl.formatMessage({ id: 'archive.search' })}
            classes={{
                root: classes.inputRoot
              , input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </Toolbar>
    </AppBar>
  </div>
  );

  const datePicker = () => (
    <div className={classes.datePickerContainer}>
      <DatePicker
        value={selectedDate}
        minDetail='month'
        maxDetail='year'
        onChange={handleDatePickerOnChange}
        tileDisabled={isTileDisabled}
        clearIcon={null}
      ></DatePicker>
    </div>
  );

  const isTileDisabled = (activeStartDate, date, view) => {
    const someMonth = activeStartDate.date.getMonth();
    const someYear = activeStartDate.date.getFullYear();
    const someActiveDate = new Date(someYear, someMonth);
    const retVal = !activeDates.current.includes(+someActiveDate);
    return retVal;
  };

  const handleDatePickerOnChange = (updatedValue) => {
    UserPrefsSingleton.setArchiveDate(updatedValue);
    setSelectedDate(updatedValue);
  };

  const archiveView = () => (
    <div className={classes.archiveContainer}>
      {datePicker()}
      <List >
        {Object.keys(pubList).map(key => (
          <ul key={key}>
            {key === "genPubs"
              ? <div></div>
              : <Img 
                  className={classes.archiveImg}
                  src={EntitiesSingleton.getCatFromEntityId(key).image}
                  > 
                </Img>
            }
            <div style={{opacity: 0.0}}>
              {activeCatKey=key} 
              {activeCatCount = 0}
            </div>

            <ImageList className={classes.pubListContainer} >
              {pubList[key].filter(isActivePub).map((somePub, i) => (
                <StorefrontItemComponent
                  key={i}
                  entity={somePub}
                  isSelected={false}
                  onClick={props.onPubButtonClick}
                ></StorefrontItemComponent>
              ))}
            </ImageList>
            {activeCatCount == 0
              ?<ImageList className={classes.pubListContainer} >
                {pubList[key].filter(isRecentPub).map((somePub, i) => (
                  <StorefrontItemComponent
                    key={i}
                    entity={somePub}
                    isSelected={false}
                    onClick={props.onPubButtonClick}
                  ></StorefrontItemComponent>
                ))}
              </ImageList>
              :<div/>
            }
            {key === "genPubs"
              ? <div/>
              :<hr className={classes.archiveHr} />
            }
          </ul>
        ))
        }
      </List>
    </div>
  );

  return (isArchiveReadyToView ? archiveView() : loader());
};

ArchiveComponent.propTypes = {
    selectedCat: PropTypes.any.isRequired
  , onPubButtonClick: PropTypes.func.isRequired
};