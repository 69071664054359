import React, { useContext, Suspense } from 'react';
import PropTypes from 'prop-types';
import {Img} from 'react-image';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Card, CardActionArea, CardContent, Typography, CardMedia } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';


import { useStyles } from '../styles/StorefrontItemStyles';
import PremiumIcon from '../components/PremiumIconComponent';
import { AccountContext } from '../providers/AccountProvider';
import StorefrontImageLoaderComponent from './StorefrontImageLoaderComponent';
import SnplusTitle from '../components/SnplusTitleComponent';

export default function StorefrontItemComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { getBoolFromString } = useContext(AccountContext);

  const loader = () => (
    <div className={classes.loaderContainer}>
      <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
    </div>
  );



  const cardImage = () => {
    const entityType = props.entity.entity_type;
    if (typeof entityType === 'undefined' || entityType === null) {
      return (<></>);
    }
    else if (entityType === 'Publication') {
      var srcList = [];
      var missingPic = "http://d5hrynxi8ghmc.cloudfront.net/2023-01-31_02-01-17_cp/bookshelf.png"
      var fallBack = props.entity.thumbnail;
      var bsImage = props.entity.thumbnail.replace(/bookshelf.png/, 'bookshelf@2x.png');
      if(bsImage.includes('bookshelf@2x.png')) {
        srcList = [bsImage, fallBack, missingPic];
      }
      else {
        srcList = [fallBack, missingPic];
      }
      var bsImage = props.entity.thumbnail;
      // console.log(`Processing the cardImage: ${bsImage}`);
      return (

        <Suspense fallback={loader()}>
          <StorefrontImageLoaderComponent
            srcUrl={srcList}
            // onPageClick={handlePreviewPageOnClick}
            // width={pageWidth}
            // height={pageHeight}
            // loader={pageLoader}
          >
          </StorefrontImageLoaderComponent>
        </Suspense>
        // <Img
        //   // className={classes.coverPage}
        //   src={[bsImage, fallBack]}
        //   // src={bsImage}
        //   loader={loader()}
        // ></Img>

      );
    }
    else if (entityType === 'Category') {
      return (
        <Suspense fallback={loader()}>
          <StorefrontImageLoaderComponent
            srcUrl={[props.entity.image]}
            // onPageClick={handlePreviewPageOnClick}
            // width={pageWidth}
            // height={pageHeight}
            // loader={pageLoader}
          >
          </StorefrontImageLoaderComponent>
        </Suspense>

        // <Img
          
        //   src={props.entity.image}
        //   loader={loader()}
        // ></Img>
      );
    }
    else {
      return (<></>);
    }
  };
  
  return (
    <Grid item xs  container className={classes.container} onClick={props.onClick.bind(this, props.entity)}>

      <Grid item container justifyContent='center'>
        <Grid item className={classes.imageStyle}> 
          {cardImage()}
        </Grid>
       
        <Grid item container justifyContent='center' spacing={1} wrap="nowrap">
          {/* <Grid item alignItems='center'>
            <SnplusTitle width='49px' height='19px'></SnplusTitle>
          </Grid> */}
          <Grid item >
            <Typography 
                className={classes.tmpTitleText} 
                variant='subtitle2'
              >
              {props.entity.name}
            </Typography>            
          </Grid>
          


          {/* <Grid item>
            <SnplusTitle width='100%' height='19px'></SnplusTitle>
          </Grid>
          <Grid item>
            {props.entity.name}
          </Grid> */}
        </Grid>
      </Grid>
      {/* <Card 
        className={(props.isSelected) ? classes.selectedPubCard : classes.pubCard} 
        elevation={4}
      >
        <CardActionArea>
          {cardImage()}
        </CardActionArea>


      </Card> */}
      {/* <SnplusTitle width='50px' height='19px' title={props.entity.name} ispremium={props.entity.premium}></SnplusTitle> */}
      {/* {(getBoolFromString(props.entity.premium))
              ? <SnplusTitle width='50%' height='19px' title={props.entity.name}></SnplusTitle>
              : <></>
        } */}
      {/* {(getBoolFromString(props.entity.premium))
                  ? <PremiumIcon width='2.5%' height='19px'></PremiumIcon>
                  : <></>
            }
            
          <Typography 
            className={classes.pubTitleText} 
            variant='subtitle1'
          >
            {props.entity.name}
          </Typography> */}
      {/* <div className={classes.floatcontainer} >

        <div className={classes.floatLogo}>
          <SnplusTitle width='100%' height='19px'></SnplusTitle>
        </div>
        <div className={classes.floatTitle}>
            <Typography 
              className={classes.tmpTitleText} 
              variant='subtitle1'
            >
              {props.entity.name}
            </Typography>
        </div>
      </div> */}
    </Grid>
  );
};

StorefrontItemComponent.propTypes = {
    entity: PropTypes.any.isRequired
  , isSelected: PropTypes.bool.isRequired
  , onClick: PropTypes.func.isRequired
};