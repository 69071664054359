import * as React from "react";

const SvgBubble = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      d="M465 250a215.07 215.07 0 0 0-413.12-83.68 215.07 215.07 0 0 0 396.24 167.36A213.69 213.69 0 0 0 465 250Z"
      style={{
        fill: "#0358a9",
      }}
    />
    <path
      d="M189.17 220.57q-23.71-23.73-23.72-61t23.72-61q23.72-23.72 61-23.72t61 23.72q23.72 23.72 23.71 61t-23.71 61q-23.73 23.72-61 23.71t-61-23.71ZM250 430a180.48 180.48 0 0 0 162.81-103 69.29 69.29 0 0 0-20.91-14.38 482.85 482.85 0 0 0-72.28-25.13 281.3 281.3 0 0 0-69.46-8.75 293.17 293.17 0 0 0-69.74 8.47q-34.74 8.48-72.57 25.41a69.12 69.12 0 0 0-20.57 14.57A180.48 180.48 0 0 0 250 430Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M250 35a215.07 215.07 0 0 1 83.68 413.12A215.07 215.07 0 0 1 166.32 51.88 213.69 213.69 0 0 1 250 35m0-35C111.93 0 0 111.93 0 250s111.93 250 250 250 250-111.93 250-250S388.07 0 250 0Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);

export default SvgBubble;
