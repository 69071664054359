import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loaderContainer: {
      width: '100%'
    , height: '100%'
    , background: theme.root.background
    , color: theme.palette.primary.main
    , display: 'grid'
    , justifyContent: 'center'
    , alignContent: 'center'
  }
}));