import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
      zIndex: 1
    , padding: '20px'
    , width: '40vw'
  }
  , closeButton: {
      position: 'absolute'
    , top: '-20px'
    , right: '-20px'
    , color: theme.palette.primary.main
    , outline: 'none !important'
  }
  , logoImage: {
    width:'75vw'
  , maxWidth: '1182px'
  }
  , personIcon: {
      width:'30px'
    , maxWidth: '30px'
    , float: 'left' 
    , marginLeft: '10px'
  }
  , personText: {
      fontWeight: 'bold'
    , fontSize: '30px'
    , position: 'relative'
    , left: '20px'
    , bottom: '5px'
  }
  , modal: {
      display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'center'
  }
  , accountContent: {
      display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'center'
  }
}));