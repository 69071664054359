import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Snackbar, Slide, ClickAwayListener, IconButton } from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import {Img} from 'react-image';
import { FormattedMessage } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { useStyles } from '../styles/SpecialOfferStyles';
import LogoImage from '../images/sn_splashscreen.png';
import PersonImage from '../images/person.svg';
import AccountComponent from '../components/AccountComponent.js';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function SpecialOfferComponent(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  // console.log(`Special Offer start up open: ${open}`);
  const handleOpen = () => {
    setOpen(true);
  };

  const onCloseButtonClick = () => {
    setOpen(false);
    props.onClose();
    
  };

  const onBackDropClick = () => {

  }

  const onClickAway = () => {
    setOpen(false);
    props.onClose();
  };
  // console.log(`Showing Special Offer: ${props.isShowing}`);
  if(props.isShowing){
    // console.log(`Special Offer open: ${open}`)
    return (
      <Modal
      className='classes.modal'
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isShowing}
      onClose={onBackDropClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
      }}
      >
        <div >
          <Img
            className={classes.personIcon}
            src={PersonImage}
            container={(img) => (
              <div>
                <Fade in={props.isShowing}>
                {/* <ClickAwayListener onClickAway={onClickAway}> */}
                  <Snackbar
                    TransitionComponent={Slide}
                    open={props.isShowing}
                    anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
                  >
                    <Paper className={classes.paper} elevation={3}>
                      <IconButton className={classes.closeButton} onClick={onCloseButtonClick}>
                        <HighlightOffRounded
                          fontSize='large'
                        >
                        </HighlightOffRounded>
                      </IconButton>
                      {img}
                      <div className={classes.personText}><FormattedMessage id='account.sign.in' /></div>
                      {console.log(`In SpecialOfferComponent starting Account Component-----`)}
                      <AccountComponent  isLongVersion={true}/>
                    </Paper>
                  </Snackbar>
                {/* </ClickAwayListener> */}
                </Fade>
              </div>
            )}
          ></Img>
        </div>
      </Modal>
    );
  }
  else {
    return null;
  }

};

SpecialOfferComponent.propTypes = {
    isShowing: PropTypes.bool.isRequired
  , onClose: PropTypes.func.isRequired
};