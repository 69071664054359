import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  premiumIconContainer: {
    //   position: 'absolute'
    // , top: '50%'
    // , left: '50%'
     background: theme.palette.primary.main
    // , transform: 'translate(-50%, -50%)'
    // , pointerEvents: 'none'
    // , margin: '5px'
     , borderRadius: '3px' 
    // , boxShadow: 'white 0px 0px 10px'
    // , display: 'flex'
    // , flexDirection: 'row'
    // , justifyContent: 'left'
    // , alignItems: 'left'
  }
  , snIcon: {
    // height: '100%'
  }
  , plusText: {
      color: 'white'
    , WebkitFontSmoothing: 'auto'
  }
  , pubTitleText: {
      color: theme.palette.secondary.main
    , textShadow: `0px 0px 10px '${theme.palette.primary.contrastText}'`
    , overflowWrap: 'break-word'
    , width: '50px'
    , userSelect: 'none'
  }
  , wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
}));