export function getLastUpdateSlug() {
    let dateStr = '';
    let rawUTCSeconds = localStorage.getItem('OVLastModifiedUTCSeconds');
    if (typeof rawUTCSeconds === 'undefined' || rawUTCSeconds === null) {
        dateStr = 'In Progress'
    }
    else {
        let lastModifiedDate = new Date(0);
        lastModifiedDate.setUTCSeconds(rawUTCSeconds);

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
            timeZone: timeZone,
        }

        const language = (navigator.languages && navigator.languages.length)
            ? navigator.languages[0]
            : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';

        dateStr = new Intl.DateTimeFormat(language, options).format(lastModifiedDate);
    }

    return `Updated: ${dateStr}`;
}

const versionStr = '1.0.41';
export let versionSlug = `${versionStr}`;
export let pluginVersion = `${versionStr}`;
