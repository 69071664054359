import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar } from '@material-ui/core';
import { AccountBox, Info, Help } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import { useStyles } from '../styles/SettingsStyles';
import AccountComponent from './AccountComponent.js';
import AboutComponent from './AboutComponent.js';
// import HelpComponent from './HelpComponent.js';
import SettingsAccountComponent from '../components/SettingsAccountComponent.js'
import PrivacyComponent from './PrivacyComponent.js';
import TsAndCsComponent from './TsandCsComponent.js'

function SettingsPanel(props) {
  const { children, settingsIndex, selectedSettingsIndex, ...other } = props;
  return (
    <div>{(settingsIndex === selectedSettingsIndex) ? <div>{children}</div> : <></>}</div>
  );
}

SettingsPanel.propTypes = {
  children: PropTypes.node
, settingsIndex: PropTypes.number.isRequired
, selectedSettingsIndex: PropTypes.number.isRequired
};

export default function SettingsComponent(props) {
  const classes = useStyles();
  const [selectedSettingsIndex, setSelectedSettingsIndex] = useState(0);
  const [shouldShowAccount, setShouldShowAccount] = useState(true);

  const propsForSettingsAtIndex = (settingsIndex) => {
    return {
        id: `settings-${settingsIndex}`
      , onClick: () => setSelectedSettingsIndex(settingsIndex)
      , selected: (settingsIndex === selectedSettingsIndex)
    };
  };

  const accountListItem = () => (
    <ListItem
      className={(selectedSettingsIndex === 0) ? classes.selectedListItem : classes.listItem}
      button
      {...propsForSettingsAtIndex(0)}
    >
      <ListItemIcon className={(selectedSettingsIndex === 0) ? classes.selectedListItemIcon : classes.listItemIcon}>
        <AccountBox ></AccountBox>
      </ListItemIcon>

      <ListItemText>
        <FormattedMessage id='settings.account.title' />
      </ListItemText>
    </ListItem>
  );

  const aboutListItem = () => (
    <ListItem
      className={(selectedSettingsIndex === 1) ? classes.selectedListItem : classes.listItem}
      button
      {...propsForSettingsAtIndex(1)}
    >
      <ListItemIcon className={(selectedSettingsIndex === 1) ? classes.selectedListItemIcon : classes.listItemIcon}>
        <Info />
      </ListItemIcon>

      <ListItemText>
        <FormattedMessage id='settings.about.title' />
      </ListItemText>
    </ListItem>
  );

  // const helpListItem = () => (
  //   <ListItem
  //     className={(selectedSettingsIndex === 2) ? classes.selectedListItem : classes.listItem}
  //     button
  //     {...propsForSettingsAtIndex(2)}
  //   >
  //     <ListItemIcon className={(selectedSettingsIndex === 2) ? classes.selectedListItemIcon : classes.listItemIcon}>
  //       <Help />
  //     </ListItemIcon>

  //     <ListItemText>
  //       <FormattedMessage id='settings.help.title' />
  //     </ListItemText>
  //   </ListItem>
  // );

  const tsandcsListItem = () => (
    <ListItem
      className={(selectedSettingsIndex === 2) ? classes.selectedListItem : classes.listItem}
      button
      {...propsForSettingsAtIndex(2)}
    >
      <ListItemIcon className={(selectedSettingsIndex === 2) ? classes.selectedListItemIcon : classes.listItemIcon}>
        <Info />
      </ListItemIcon>

      <ListItemText>
        <FormattedMessage id='settings.tsandcs.title' />
      </ListItemText>
    </ListItem>
  );

  const privacyListItem = () => (
    <ListItem
      className={(selectedSettingsIndex === 3) ? classes.selectedListItem : classes.listItem}
      button
      {...propsForSettingsAtIndex(3)}
    >
      <ListItemIcon className={(selectedSettingsIndex === 3) ? classes.selectedListItemIcon : classes.listItemIcon}>
        <Info />
      </ListItemIcon>

      <ListItemText>
        <FormattedMessage id='settings.privacy.title' />
      </ListItemText>
    </ListItem>
  );

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        anchor='left'
        classes={{ paper: classes.drawerPaper }}
      >
       {/*   <Toolbar /> NOTE: The empty toolbar is for spacing. */}
        <div className={classes.drawerContainer}>
          <List>
            <Divider />
            {accountListItem()}
            <Divider />
            {aboutListItem()}
            <Divider />
            {tsandcsListItem()}
            <Divider />
            {privacyListItem()}
            <Divider />
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <SettingsPanel settingsIndex={0} selectedSettingsIndex={selectedSettingsIndex}>
          {/* <AccountComponent></AccountComponent> */}
          <SettingsAccountComponent></SettingsAccountComponent>
          {/* <iframe 
            src="https://sso.sn.at/auth/authorize?client_id=snweb_authorize&amp;ajaxMode=1&amp;responsetype=event&amp;domain=https://www.sn.at/&amp;formId=overlay_LoginScene_1"
            style={{ width: '100%', height: '100vh', border: 'none' }}
          ></iframe> */}
        </SettingsPanel>

        <SettingsPanel settingsIndex={1} selectedSettingsIndex={selectedSettingsIndex}>
          <AboutComponent />
        </SettingsPanel>

        <SettingsPanel settingsIndex={2} selectedSettingsIndex={selectedSettingsIndex}>
          <TsAndCsComponent />
        </SettingsPanel>

        <SettingsPanel settingsIndex={3} selectedSettingsIndex={selectedSettingsIndex}>
          <PrivacyComponent />
        </SettingsPanel>
      </main>
    </div>
  );
};