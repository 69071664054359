import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
      marginTop: '20px'
    , marginLeft: '10px'
    , marginRight: '10px'
    , textAlign:'center'
    // , border: 'solid 2px red'
  }
  , floatcontainer: {
      marginTop: '1px'
    , marginLeft: '1px'
    , marginRight: '1px'
    , textAlign:'center'
    , border: 'solid 2px green'
  }
  , floatchild: {
      width: '50%'
    , float: 'left'
    , padding: 'px'
    , border: 'solid 2px yellow'
  }
  , floatLogo: {
    width: '30%'
  , float: 'left'
  , padding: 'px'
  , border: 'solid 2px yellow'
}
, floatTitle: {
  width: '70%'
, float: 'left'
, padding: 'px'
, border: 'solid 2px yellow'
}
  , pubCard: {
    display: 'inline-block'
  }
  , selectedPubCard: {
      display: 'inline-block'
    , border: `2px solid ${theme.palette.secondary.main}`
  }
  , loaderContainer: {
      display: 'flex'
    , justifyContent: 'center'
    , alignContent: 'center'
  }
  , pubImage: {
      marginLeft: 'auto'
    , marginRight: 'auto'
    , display: 'block'
    , maxWidth: theme.pagePicker.maxThumbWidth
    , maxHeight: theme.pagePicker.maxThumbHeight
    , width: 'auto'
    , height: 'auto'
  }
  , pubTitleText: {
      color: theme.palette.secondary.main
    , textShadow: `0px 0px 10px '${theme.palette.primary.contrastText}'`
    , overflowWrap: 'break-word'
    , width: theme.pagePicker.maxThumbWidth
    , userSelect: 'none'
  }
  , tmpTitleText: {
    color: theme.palette.secondary.dark
  , marginTop: '2px'
  , textShadow: `0px 0px 10px '${theme.palette.primary.contrastText}'`
  // , overflowWrap: 'break-word'
  , userSelect: 'none'
 }
  , coverPage:{
     marginLeft: 'auto'
    , marginRight: 'auto'
    , display: 'block'
    , maxWidth: theme.coverPage.maxThumbWidth
    , maxHeight: theme.coverPage.maxThumbHeight
    , width: 'auto'
    , height: 'auto'
  }
  , imageStyle: {
    marginBottom: '10px'
    // , border: `2px solid ${theme.palette.secondary.main}`
  }
  , cardPage:{
  //   marginLeft: 'auto'
  //  , marginRight: 'auto'
  //  , display: 'block'
  //  , minWidth: '200px'
  //  , width: 'auto'
  //  , height: 'auto'
  //  , maxWidth: theme.coverPage.maxThumbWidth
  //  , maxHeight: theme.coverPage.maxThumbHeight
  //  , width: 'auto'
      height: 0
      // ,paddingTop: '56.25%' // 16:9,
      // ,marginTop:'30'
  //  , maxWidth: 345
 }
 , cardPageText: {
   flexGrow: 1
  ,justifyContent:'center'
  ,alignItems: 'center'
 }
}));