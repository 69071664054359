const accountReducer = (state = {}, action) => {
  switch(action.type) {
    case 'SET_STATUS':
      return {
        ...state
        , status: action.data
      };

    case 'SET_ACCESS_TOKEN':
      return {
        ...state
        , accessToken: action.data
      };

    case 'SET_ACCESS_EXPIRATION':
      return {
        ...state
        , accessExpiration: action.data
      };

    case 'SET_REFRESH_TOKEN':
      return {
        ...state
        , refreshToken: action.data
      }
    
    case 'SET_EMAIL':
      return {
        ...state
        , email: action.data
      };

    case 'SET_SN_PLUS':
      return {
        ...state
        , snPlus: action.data
      };

    case 'SET_SN_PLUS_TS':
      return {
        ...state
        , snPlusTS: action.data
      };

    default:
      return state;
  }
};

export default accountReducer;