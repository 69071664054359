import { createStore } from 'redux';
import rootReducers from './reducers';

import * as OV from '../api/OVConstants.js';

function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem(OV.LS_REDUX, serialisedState);
  } catch (error) {
    console.error(`Error saving to localStorage: ${error}`);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem(OV.LS_REDUX);
    if (typeof serialisedState === 'undefined' || serialisedState === null) { return undefined; }
    return JSON.parse(serialisedState);
  } catch (error) {
    console.error(`Error loading from localStorage: ${error}`);
    return undefined;
  }
}

const store = createStore(rootReducers, loadFromLocalStorage());
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;