import { makeStyles } from '@material-ui/core/styles';

import './ShowcaseComponent.css';

export const useStyles = makeStyles((theme) => ({
  loaderContainer: {
      position: 'absolute'
    , top: '50%'
    , left: '50%'
    , transform: 'translate(-50%, -50%)'
  }
  , carouselContainer: {
      position: 'absolute'
    , bottom: '0px'
    , width: '100%'
    , maxHeight: `calc(${theme.showcasePicker.maxThumbHeight} + ${theme.showcasePicker.reflectionHeight})`
  }
  , showcaseImgContainer: {
      width: '100%'
    , height: '100%'
    , paddingTop: '10px'
    , paddingLeft: '5px'
    , paddingRight: '5px'
    , display: 'flex'
    , flexDirection: 'column-reverse'
  }
  , showcaseImg: {
      userSelect: 'none'
    , maxWidth: '100%'
    , maxHeight: `calc(100% - ${theme.showcasePicker.reflectionHeight})`
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
    , display: 'block'
    , margin: '0 auto'
    , WebkitBoxReflect: 'below 10px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(75%, transparent) , to(rgba(250, 250, 250, 0.35)))'
    , cursor: 'pointer'
  }
}));