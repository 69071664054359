class IPValidatorSingleton {
    constructor() {
      if (!IPValidatorSingleton.instance) {
        IPValidatorSingleton.instance = this;
        // this.isValidated = false;
        this.url = 'https://snmps.mirado1.info/ipvalidate/ipvalidate';
        this.data = {};
        this.data.isIPValidated = false;
        this.data.myip = '';
      }
      return IPValidatorSingleton.instance;
    }

    checkIsValidated() {
        return new Promise((resolve, reject) => {
          fetch(this.url)
            .then((response) => response.json())
            .then((responseJSON) => this._validateResponseJSON(responseJSON))
            .then((responseJSON) => this._processResponseJSON(responseJSON))
            .then(() => resolve())
            .catch((error) => reject(error));
        });
      }

      isIPValidated(){
        console.log(`isIPValidated: ${this.data.isIPValidated}`);
        return this.data.isIPValidated;
      }

      _validateResponseJSON(responseJSON) {
        if (typeof responseJSON.status === 'undefined' 
        ||  responseJSON.status === null
        ||  responseJSON.status !== 'OK'
        ) {
          throw new Error('Bad validation request');
        }
        
        return responseJSON;
      }
    
      _processResponseJSON(responseJSON) {
        if(responseJSON.status === 'OK'){
            if(responseJSON.payload.valid == true){
                this.data.isIPValidated = true;
            }
            else {
                this.data.isIPValidated = false;
            }
            this.data.myip = responseJSON.payload.public_ip;
            console.log(`checkIPValidated: ${this.data.isIPValidated} for ${this.data.myip}`);
        }
      }
}

const instance = new IPValidatorSingleton();
Object.freeze(instance);

export default instance;