import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  catPickerContainer: {
      width: '100%'
    , paddingTop: '5px'
    , paddingBottom: '5px'
    , background: 'linear-gradient(#f5f5f5, #dedfdf)'
    , borderBottom: '2px solid #ffffff'
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
  }
  , catLoaderContainer: {
      display: 'flex'
    , justifyContent: 'center'
    , alignContent: 'center'
  }
  , catButton: {
      width: `calc(${theme.catPicker.maxThumbWidth} + 10px)`
    , marginLeft: '5px'
    , marginRight: '5px'
    , marginTop: '10px'
    , marginBottom: '5px'
    , background: 'linear-gradient(#fcfbfc, #a7a7a7)'
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
    , outline: 'none !important'
  }
  , selectedCatButton: {
      width: `calc(${theme.catPicker.maxThumbWidth} + 10px)`
    , marginLeft: '5px'
    , marginRight: '5px'
    , marginTop: '10px'
    , marginBottom: '5px'
    , background: 'linear-gradient(#fcfbfc, #a7a7a7)'
    , border: `3px solid ${theme.palette.secondary.main}`
    , borderRadius: '7px'
    , outline: 'none !important'
  }
  , catImg: {
      maxWidth: theme.catPicker.maxThumbWidth
    , maxHeight: theme.catPicker.maxThumbHeight
  }
}));