import { makeStyles, alpha } from '@material-ui/core/styles';

import './SlideshowViewerComponent.css';

export const useStyles = makeStyles((theme) => ({
  slideshowViewerContainer: {
      background: theme.root.background
    , position: 'absolute'
    , top: '0px'
    , left: '0px'
    , right: '0px'
    , bottom: '0px'
    , height: '100%'
  }
  , slideshowContainer: {
    height: '100%'
  }
  , appBar: {
      background: `linear-gradient(${theme.toolbar.backgroundStartGradient}, ${theme.toolbar.backgroundEndGradient})`
    , color: theme.toolbar.textColor
  }
  , iconButton: {
      color: theme.toolbar.textColor
    , outline: 'none !important'
    , padding: '0px !important'
  }
  , stepper: {
    backgroundColor: '#00000000'
  }
  , stepperPrevButton: {
      outline: 'none !important'
    , color: theme.toolbar.textColor
    , paddingRight: '20px'
  }
  , stepperNextButton: {
      outline: 'none !important'
    , color: theme.toolbar.textColor
    , paddingLeft: '20px'
  }
  , flexibleSpace: {
    flexGrow: 1,
  }
  , slideshowItemContainer: {
    height: '100%'
  }
  , loaderContainer: {
      position: 'absolute'
    , top: '50%'
    , left: '50%'
    , transform: 'translate(-50%, -50%)'
  }
  , slidehowItemImg: {
      display: 'block'
    , margin: 'auto'
    , width: '100%'
    , height: '100%'
    , objectFit: 'contain'
  }
  , slideshowItemCaptionContainer: {
      padding: '10px'
    , position: 'absolute'
    , bottom: '0px'
    , background: `linear-gradient(${alpha(theme.toolbar.backgroundStartGradient, 0.85)}, ${alpha(theme.toolbar.backgroundEndGradient, 0.85)})`
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
    , width: '100%'
  }
}));