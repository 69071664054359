export const setAccountStatus = (status) => {
  return { type: 'SET_STATUS', data: status };
};

export const setAccountAccessToken = (accessToken) => {
  return { type: 'SET_ACCESS_TOKEN', data: accessToken };
};

export const setAccountAccessExpiration = (accessExpiration) => {
  return { type: 'SET_ACCESS_EXPIRATION', data: accessExpiration };
};

export const setAccountRefreshToken = (refreshToken) => {
  return { type: 'SET_REFRESH_TOKEN', data: refreshToken };
};

export const setAccountEmail = (email) => {
  return { type: 'SET_EMAIL', data: email };
};

export const setAccountSNPlus = (snPlus) => {
  return { type: 'SET_SN_PLUS', data: snPlus };
};

export const setAccountSNPlusTimeStamp = (snPlusTS) => {
  return { type: 'SET_SN_PLUS_TS', data: snPlusTS };
};