import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useStyles } from '../styles/SnplusTitleStyles';
import SNPlusIcon from '../images/sn_plus.svg';
import { AccountContext } from '../providers/AccountProvider';

export default function SnPlusTitleComponent(props) {
  const classes = useStyles();
  const { getBoolFromString } = useContext(AccountContext);

  const handleImgOnDragStart = (event) => {
    event.preventDefault();
  };

  return (
    <div 
      className={classes.premiumIconContainer}
      style={{ width: '100%', height: '100%' }}
    >
      <img
        className={classes.snIcon}
        src={SNPlusIcon} 
        onDragStart={handleImgOnDragStart}
      >

      </img>

    </div>
  );
};

SnPlusTitleComponent.propTypes = {
    width: PropTypes.string.isRequired
  , height: PropTypes.string.isRequired
  // , title: PropTypes.string.isRequired
  // , ispremium: PropTypes.string.isRequired
};