import { makeStyles, alpha } from '@material-ui/core/styles';
// import { Opacity } from '@material-ui/icons';

import './ArchiveComponent.css';

export const useStyles = makeStyles((theme) => ({
  archiveContainer: {
    // height: '100%'
    paddingBottom: '226px' // 226 = 72 (tab bar) + 110 (cat picker) + 44 (date picker)
  }
  , appBar: {
    position: 'relative'
  }
  , loaderContainer: {
      position: 'absolute'
    , top: '50%'
    , left: '50%'
    , transform: 'translate(-50%, -50%)'
    , msTransform: 'translate(-50%, -50%)'
  }
  , pubListContainer: {
    justifyContent: 'center'
    , overflow: 'hidden'
  }
  , searchContainer: {
      position: 'relative'
    , borderRadius: theme.shape.borderRadius
    , backgroundColor: alpha(theme.palette.common.white, 0.15)
    , '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    }
    , marginRight: theme.spacing(2)
    , marginLeft: 0
    , marginTop: '10px'
    , width: '100%'
    , [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3)
      , width: '100%'
    }
  }
  , searchIconContainer: {
      padding: theme.spacing(0, 2)
    , height: '100%'
    , position: 'absolute'
    , pointerEvents: 'none'
    , display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'center'
  }
  , inputRoot: {
    color: 'inherit'
  }
  , inputInput: {
      padding: theme.spacing(1, 1, 1, 0)
    , paddingLeft: `calc(1em + ${theme.spacing(4)}px)` // vertical padding + font size from searchIcon
    , transition: theme.transitions.create('width')
    , width: '100%'
    , [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
  , datePickerContainer: {
      display: 'flex'
    , justifyContent: 'center'
    , paddingTop: '15px'
  }
  , archiveImg: {
      maxWidth: theme.catPicker.maxThumbWidth
    , maxHeight: theme.catPicker.maxThumbHeight
  }
  , archiveHr : {
    width: '80%',
    height: '2px',
    marginBottom: '10px',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    opacity: '0.0'
  }
}));