import React, { useEffect, useRef, useState, createRef, useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, MobileStepper, Button, Switch, FormControlLabel } from '@material-ui/core';
import { ArrowBackIos, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Img from 'react-image';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { FormattedMessage } from 'react-intl';
import history from "history/browser";

import { useStyles } from '../styles/SlideshowViewerStyles';
import * as OV from '../api/OVConstants.js';
import UserPrefsSingleton from '../api/singletons/UserPrefsSingleton.js';

export default function SlideshowViewerComponent(props) {
  // const { history } = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const backPath = useRef(OV.RP_PUBVIEW);
  const carousel = createRef();
  const [slideshowItems, setSlideshowItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startSlideIndex, setStartSlideIndex] = useState(0);
  const [captionDescription, setCaptionDescription] = useState('');
  const [captionCopyright, setCaptionCopyright] = useState('');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (typeof history.location.state !== 'undefined' 
    &&  history.location.state !== null
    ) {
      if (typeof history.location.state.backPath !== 'undefined' 
      &&  history.location.state.backPath !== null
      ) {
        backPath.current = history.location.state.backPath;
      }

      if (typeof history.location.state.markup !== 'undefined' 
      &&  history.location.state.markup !== null
      ) {
        setSlideshowItems(history.location.state.markup.slideshowItems);
      }

      if (typeof history.location.state.startSlideIndex !== 'undefined'
      && history.location.state.startSlideIndex !== null) {
        const slideIndex = parseInt(history.location.state.startSlideIndex);
        setStartSlideIndex(slideIndex);
        setCurrentSlide(slideIndex);
      }
    }

    return () => {}
  }, []);

  const appBar = () => (
    <AppBar className={classes.appBar}>
      <Toolbar variant='dense'>
        {backButton()}
        {flexibleSpace()}
        {stepper()}
        {flexibleSpace()}
        {captionToggle()}    
      </Toolbar>
    </AppBar>
  );

  const backButton = () => (
    <IconButton className={classes.iconButton} onClick={handleBackButtonOnClick}>
      <ArrowBackIos />
    </IconButton>
  );

  const flexibleSpace = () => (
    <div className={classes.flexibleSpace}></div>
  );

  const stepper = () => (
    <MobileStepper
      className={classes.stepper}
      position='static'
      variant={(slideshowItems.length > 10) ? 'text' : 'dots' }
      steps={slideshowItems.length}
      activeStep={currentSlide}
      backButton={stepperPrevButton()}
      nextButton={stepperNextButton()}
    ></MobileStepper>
  );

  const stepperPrevButton = () => (
    <Button
      className={classes.stepperPrevButton} 
      size='small' 
      onClick={handleStepperPrevOnClick}
    >
      <KeyboardArrowLeft />
      <FormattedMessage id='slideshow.prev' />
    </Button>
  );

  const stepperNextButton = () => (
    <Button
      className={classes.stepperNextButton}
      size='small'
      onClick={handleStepperNextOnClick}
    >
      <FormattedMessage id='slideshow.next' />
      <KeyboardArrowRight />
    </Button>
  );
  
  const hasCaptions = () => {
    return (
      (typeof captionDescription !== 'undefined' && captionDescription !== null && captionDescription.length > 0) 
      || (typeof captionCopyright !== 'undefined' && captionCopyright !== null && captionCopyright.length > 0)
    );
  };

  const captionToggle = () => (
    <IconButton className={classes.iconButton}>
      <FormControlLabel 
        control={<Switch checked={UserPrefsSingleton.isCaptioning()} onChange={handleCaptionToggleOnChange}></Switch>}
        labelPlacement='start'
        label={<FormattedMessage id='slideshow.captions' />}
      >
      </FormControlLabel>
    </IconButton>
  );

  const loader = () => {
    return (
      <div className={classes.loaderContainer}>
        <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
      </div>
    );
  };

  const setCaptionForIndex = (itemIndex) => {
    const slideshowItem = slideshowItems[itemIndex];
    setCaptionDescription(slideshowItem.captionDescription);
    setCaptionCopyright(slideshowItem.captionCopyright);
  };

  const handleBackButtonOnClick = () => {
    if (backPath.current === OV.RP_PUBVIEW) {
      delete history.location.state.markup;
    }
    history.replace(backPath.current, history.location.state);
  };

  const handleSlideshowItemOnDragStart = (event) => {
    event.preventDefault();
  };

  const handleCarouselOnInitialized = () => {
    setCaptionForIndex(currentSlide);
  };

  const handleCarouselOnSlideChanged = (event) => {
    setCaptionForIndex(event.slide);
    setCurrentSlide(event.slide);
  };

  const handleStepperPrevOnClick = () => {
    carousel.current.slidePrev();
  };

  const handleStepperNextOnClick = () => {
    carousel.current.slideNext();
  };

  const handleCaptionToggleOnChange = () => {
    UserPrefsSingleton.setIsCaptioning(!UserPrefsSingleton.isCaptioning());
    forceUpdate();
  };

  return (
    <div className={classes.slideshowViewerContainer}>
      {appBar()}
      {(slideshowItems.length > 0)
        ? <div id='slideshowContainer' className={classes.slideshowContainer}>
            <Toolbar variant='dense' /> {/* NOTE: The empty toolbar is for spacing. */}
            <AliceCarousel
              ref={carousel}
              mouseTrackingEnabled={true}
              dotsDisabled={true}
              buttonsDisabled={true}
              preservePosition={true}
              startIndex={startSlideIndex}
              onInitialized={handleCarouselOnInitialized}
              onSlideChanged={handleCarouselOnSlideChanged}
            >
              {slideshowItems.map((someSlideshowItem, index) => {
                return (
                  <div key={index} className={classes.slideshowItemContainer}>
                    <Img
                      className={classes.slidehowItemImg}
                      src={someSlideshowItem.url}
                      onDragStart={handleSlideshowItemOnDragStart}
                      loader={loader()}
                    ></Img>
                  </div>
                );
              })}
            </AliceCarousel>

            {(UserPrefsSingleton.isCaptioning() && hasCaptions())
              ? <div className={classes.slideshowItemCaptionContainer}>
                  <Typography variant='subtitle1'>{captionDescription}</Typography>
                  <Typography variant='subtitle2'>{captionCopyright}</Typography>
                </div>
              : <></>
            }

          </div>
        : <></>}
    </div>
  );
};