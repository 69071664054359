import React from 'react';
import { Route, Routes } from 'react-router-dom';

import * as OV from './api/OVConstants.js';
import MainNavComponent from './components/MainNavComponent.js';
import LazyPubViewerComponent from './components/LazyPubViewerComponent.js';
import HTMLMarkupViewerComponent from './components/HTMLMarkupViewerComponent.js';
import SlideshowViewerComponent from './components/SlideshowViewerComponent.js';

export const SNRoutes = (props) => {
    return (
        <div>
            <Routes>
                <Route path={OV.RP_ROOT}  element={<MainNavComponent />} />
                <Route path={OV.RP_PUBVIEW} element={<LazyPubViewerComponent />} />
                <Route path={OV.RP_ARTICLE} element={<HTMLMarkupViewerComponent />} />
                <Route path={OV.RP_SLIDESHOW} element={<SlideshowViewerComponent />} />
            </Routes>
        </div>
    );

};