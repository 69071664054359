import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    loaderContainer: {
        position: 'absolute'
        , top: '50%'
        , left: '50%'
        , transform: 'translate(-50%, -50%)'
        , msTransform: 'translate(-50%, -50%)'
    }

}));