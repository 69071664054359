import React, { useEffect, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'regenerator-runtime/runtime';

import * as OV from '../api/OVConstants.js';
import { 
    accountStatusSelector
  , accountAccessTokenSelector
  , accountAccessExpirationSelector
  , accountRefreshTokenSelector
  , accountSNPlusSelector
  , accountSNPlusTimeStampSelector
} from '../redux/selectors/AccountSelectors.js';
import { 
    setAccountStatus
  , setAccountAccessToken
  , setAccountAccessExpiration
  , setAccountRefreshToken
  , setAccountEmail
  , setAccountSNPlus
  , setAccountSNPlusTimeStamp
} from '../redux/actions/AccountActions.js';

export const AccountContext = createContext({});

export default function AccountProvider({ children }) {
  const dispatch = useDispatch();
  const accountStatus = useSelector(accountStatusSelector);
  const accountAccessToken = useSelector(accountAccessTokenSelector);
  const accountAccessExpiration = useSelector(accountAccessExpirationSelector);
  const accountRefreshToken = useSelector(accountRefreshTokenSelector);
  const accountSNPlus = useSelector(accountSNPlusSelector);
  const accountSNPlusTimeStamp = useSelector(accountSNPlusTimeStampSelector);
  // const redirectUri = 'https://epaper.sn.at/';
  // const redirectUri = 'https://snepaper.miradoweb.net/';
  const redirectUri = 'https://snmps.mirado1.info/ipvalidate/verify';
  const clientID = 'snweb_epaper';
  const clientSecret = 'RqoCYGwIEudumj5YYEtivRdrIn7Usukar+8Xc5i95bX6VOxKw4iuSC1IzawcpkUC';

  useEffect(() => {
    checkIfPubIsRestricted({ premium: 'true' })
    .then((result) => {
      if (result.isPubRestricted) {
        dispatch(setAccountStatus(OV.STATUS_PROMPT));
      }
    });
  }, []);

  const checkIfPubIsRestricted = (pub) => {
    return new Promise(async (resolve, reject) => {
      let isPubRestricted = true;
      try {
        const isPremium = getBoolFromString(pub.premium);
        if (!isPremium) { isPubRestricted = false; return; }

        if (accountStatus !== OV.STATUS_SUCCESS) { return; }

        switch(`${accountSNPlus}`) {
          case '0': return;
          case '1': break;
          case '2': {
            if ((Date.now() / 1000) > accountSNPlusTimeStamp) { return; }
            break;
          }
          default: return;
        };

        if ( typeof accountAccessToken === 'undefined'
          || accountAccessToken === null
          || accountAccessToken === ''
        ) { return; }

        const logonStatusUrl = new URL(
            `https://sso.sn.at/auth/logonstatus`
          + `?access_token=${accountAccessToken}`
          + `&client_id=${clientID}`
        );

        if (Date.now() / 1000 < accountAccessExpiration) {
          await fetch(logonStatusUrl, { method: 'POST' })
          .then((logonStatusResponse) => _validateResponse(logonStatusResponse))
          .then((logonStatusResponse) => logonStatusResponse.json())
          .then((logonStatusResponseJSON) => {
            if (logonStatusResponseJSON.code === 2000) {
              isPubRestricted = false;
              return;
            }
          });
        }

        if (isPubRestricted) {
          const refreshUrl = new URL('https://sso.sn.at/auth/refresh');

          const refreshParams = new URLSearchParams();
          refreshParams.append('client_id', clientID);
          refreshParams.append('client_secret', clientSecret);
          refreshParams.append('refresh_token', accountRefreshToken);
          refreshParams.append('grant_type', 'refresh_token');

          await fetch(refreshUrl, { method: 'POST', body: refreshParams })
          .then((refreshResponse) => _validateResponse(refreshResponse))
          .then((refreshResponse) => refreshResponse.json())
          .then((refreshResponseJSON) => {
            const updatedAccessToken = refreshResponseJSON.access_token;
            const updatedRefreshToken = refreshResponseJSON.refresh_token;
            if ( typeof updatedAccessToken !== 'undefined'
              && updatedAccessToken !== null
              && typeof updatedRefreshToken !== 'undefined'
              && updatedRefreshToken !== null
            ){
              dispatch(setAccountAccessExpiration((Date.now() / 1000) + refreshResponseJSON.expires_in));
              dispatch(setAccountAccessToken(updatedAccessToken));
              dispatch(setAccountRefreshToken(updatedRefreshToken));

              isPubRestricted = false;
              return;
            }
          });
        }
      } 
      finally {
        resolve({ 'isPubRestricted': isPubRestricted });
      }
    });
  };

  const loginWithCredentials = (username, password) => {
    dispatch(setAccountStatus(OV.STATUS_BUSY));

    const authorizeUrl = new URL(
        `https://sso.sn.at/auth/authorize`
      // + `?client_id=${clientID}`
      // + `&redirect_uri=${redirectUri}`
      // + `&username=${username}`
      // + `&password=${password}`
    );

    let authorizeParams = new URLSearchParams();
    authorizeParams.append('client_id', clientID);
    authorizeParams.append('redirect_uri', redirectUri);
    authorizeParams.append('username', username);
    authorizeParams.append('password', password);

    // fetch(authorizeUrl, { method: 'GET' })
    fetch(authorizeUrl, { method: 'POST', body: authorizeParams })
    .then((authorizeResponse) => _validateResponse(authorizeResponse))
    .then((authorizeResponse) => {
      if (!authorizeResponse.redirected) { throw new Error(`Response not redirected`); }

      const responseUrl = new URL(authorizeResponse.url);
      const redirectedParams = new URLSearchParams(responseUrl.search);
      const code = redirectedParams.get('code');

      let accessTokenParams = new URLSearchParams();
      accessTokenParams.append('client_id', clientID);
      accessTokenParams.append('code', code);
      accessTokenParams.append('client_secret', clientSecret);
      accessTokenParams.append('grant_type', 'authorization_code');
      accessTokenParams.append('redirect_uri', redirectUri);

      const accessTokenUrl = new URL(
          `https://sso.sn.at/auth/accesstoken`
        + `?client_id=${clientID}`
        + `&code=${code}`
        + `&client_secret=${clientSecret}`
        + `&grant_type=authorization_code`
        + `&redirect_uri=${redirectUri}`
      );

      fetch(accessTokenUrl, { method: 'POST', body: accessTokenParams })
      .then((accessTokenResponse) => _validateResponse(accessTokenResponse))
      .then((accessTokenResponse) => accessTokenResponse.json())
      .then((accessTokenResponseJSON) => {
        const responseAccessToken = accessTokenResponseJSON.access_token;

        const getUserUrl = new URL(
            `https://sso.sn.at/user/getuser`
          + `?client_id=${clientID}`
          + `&access_token=${responseAccessToken}`
          + `&redirect_uri=${redirectUri}`
        );

        fetch(getUserUrl, { method: 'GET' })
        .then((getUserResponse) => _validateResponse(getUserResponse))
        .then((getUserResponse) => getUserResponse.json())
        .then((getUserResponseJSON) => _loginWithResponseJSON(getUserResponseJSON, accessTokenResponseJSON))
        .catch((error) => _handleLoginError(error));
      })
      .catch((error) => _handleLoginError(error));
    })
    .catch((error) => _handleLoginError(error));
  };

  const logout = () => {
    dispatch(setAccountAccessToken(undefined));
    dispatch(setAccountAccessExpiration(undefined));
    dispatch(setAccountEmail(undefined));
    dispatch(setAccountSNPlus(undefined));
    dispatch(setAccountSNPlusTimeStamp(undefined));
    dispatch(setAccountStatus(OV.STATUS_PROMPT));
  };

  
  const getBoolFromString = (stringVal) => {
    return !!JSON.parse(String(stringVal).toLowerCase());
  }

  const _validateResponse = (response) => {
    if (response.status !== 200) {
      throw new Error(`Bad server response`);
    }
    return response;
  };

  const _handleLoginError = (error) => {
    console.error(`Login error: ${error}`);
    logout();
    dispatch(setAccountStatus(OV.STATUS_ERROR));
  }

  const _snplusCheck = (getUserResponseJSON) => {

    const checkList  = ['SNSDI', 'SNSONLINE'];
    var snplusCheck = 0;
    var i = 0;
    for(i in checkList){
      var tmp = getUserResponseJSON['arbitrary'][checkList[i]];
      if(tmp !== undefined) {
        console.log(`json: ${tmp}`);
        var tmpJson = JSON.parse(tmp)
        var valid_til = tmpJson.HA.valid_til;
        snplusCheck = 1;
        dispatch(setAccountSNPlusTimeStamp(valid_til));
        break;
      }
    }
    if(snplusCheck < 1) {
      // snPlus not validated yet
      var tmpPlus = getUserResponseJSON.arbitrary.snplus;
      if((tmpPlus == 1 ) || (tmpPlus == 1)) {
        snplusCheck = 1;
      }
    }
    dispatch(setAccountSNPlus(snplusCheck)); 
  }

  const _loginWithResponseJSON = (getUserResponseJSON, accessTokenResponseJSON) => {
    dispatch(setAccountAccessExpiration((Date.now() / 1000) + accessTokenResponseJSON.expires_in));
    dispatch(setAccountAccessToken(accessTokenResponseJSON.access_token));
    dispatch(setAccountRefreshToken(accessTokenResponseJSON.refresh_token));
    dispatch(setAccountEmail(getUserResponseJSON.email));
    // dispatch(setAccountSNPlus(getUserResponseJSON.arbitrary.snplus));
    _snplusCheck(getUserResponseJSON)
    // var tmpSNSDI = getUserResponseJSON.arbitrary.SNSDI;
    // var tmpJson = JSON.parse(tmpSNSDI)
    // var valid_til = tmpJson.HA.valid_til;
    // dispatch(setAccountSNPlusTimeStamp(valid_til));
    dispatch(setAccountStatus(OV.STATUS_SUCCESS));
  }

  return (
    <AccountContext.Provider 
      value={{ loginWithCredentials, logout, checkIfPubIsRestricted, getBoolFromString }}
    >{children}</AccountContext.Provider>
  );
};