import { makeStyles, alpha } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  }
  , drawer: {
      width: drawerWidth
    , flexShrink: 0
  }
  , drawerPaper: {
    width: drawerWidth
  }
  , drawerContainer: {
      overflow: 'auto'
    , height: '100%'
    , background: `linear-gradient(90deg, ${theme.toolbar.backgroundStartGradient}, ${theme.toolbar.backgroundEndGradient})`
  }
  , listItem: {
    color: alpha(theme.palette.primary.main, 0.73)
  }
  , selectedListItem: {
      borderRight: `2px solid ${theme.palette.secondary.main}`
    , color: theme.palette.primary.main
  }
  , listItemIcon: {
    color: alpha(theme.palette.primary.main, 0.73)
  }
  , selectedListItemIcon: {
    color: theme.palette.primary.main
  }
  , content: {
      flexGrow: 1
    , height: '100vh'
    , padding: theme.spacing(3)
    , background: theme.root.background
  } 
}));