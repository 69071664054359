import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
      width: '100%'
    , height: '100%'
  }
  , articleContainer: {
      width: '100%'
    , height: '100%'
  }
  , appBar: {
      background: `linear-gradient(${theme.toolbar.backgroundStartGradient}, ${theme.toolbar.backgroundEndGradient})`
    , color: theme.toolbar.textColor
  }
  , iconButton: {
      color: theme.toolbar.textColor
    , outline: 'none !important'
  }
  , loaderContainer: {
      position: 'absolute'
    , top: '50%'
    , left: '50%'
    , transform: 'translate(-50%, -50%)'
    , msTransform: 'translate(-50%, -50%)'
  }
  , toolbarOverlay: {
      position: 'absolute'
    , display: 'flex'
    , width: '72px'
    , height: '51px'
    , top: '0'
    , right: '64px'
    , bottom: '0'
    , marginLeft: 'auto'
    , marginRight: 'auto'
    , justifyContent: 'center'
    , alignItems: 'center'
  }
  , toolbarButton: {
      padding: '2px'
    , cursor: 'pointer'
    , filter: 'invert(15%) sepia(0%) saturate(1%) hue-rotate(76deg) brightness(91%) contrast(86%)'
  }
}));