import React, {Suspense} from 'react'
import {useImage} from 'react-image'
import PropTypes from 'prop-types';
import { useStyles } from '../styles/PubViewerStyles';


export default function StorefrontImageLoaderComponent(props) {
    // console.log(`StorefrontImageLoaderComponent srcUrl: ${props.srcUrl}`);
    const classes = useStyles();
    const {src} = useImage({
        srcList: props.srcUrl,
    })
    // console.log(`StorefrontImageLoaderComponent src: ${src}`);
    return <img 
        src={src}
        draggable={false}
        // className={classes.pagePreviewImage}
        // onClick = {props.onPageClick}
        // width = {props.width}
        // height = {props.height}
        // loader = {props.loader}
        />


    // return (
    //   <Suspense>
    //     <LoaderComponent />
    //   </Suspense>
    // )
}


StorefrontImageLoaderComponent.propTypes = {
    srcUrl: PropTypes.array
//   , onPageClick: PropTypes.func
//   , width: PropTypes.number
//   , height: PropTypes.number
//   , loader: PropTypes.func
};

StorefrontImageLoaderComponent.defaultProps = {
  
};