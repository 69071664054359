import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  premiumIconContainer: {
      position: 'absolute'
    , top: '50%'
    , left: '50%'
    , background: theme.palette.primary.main
    , transform: 'translate(-50%, -50%)'
    , pointerEvents: 'none'
    , borderRadius: '10px' 
    , boxShadow: 'white 0px 0px 10px'
    , display: 'flex'
    , flexDirection: 'row'
    , justifyContent: 'center'
    , alignItems: 'center'
  }
  , snIcon: {
      height: '100%'
  }
  , plusText: {
      color: 'white'
    , WebkitFontSmoothing: 'auto'
  }
}));