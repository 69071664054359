import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

export default function ArticleIframe(props) {
  const [iFrameAttributes, setIFrameAttributes] = useState(null);
  const [iFrameElement, setIFrameElement] = useState(null);
  const isFirstPostMessage = useRef(true);

  useEffect(() => {
    window.addEventListener('message', onReceiveMessage);
    return () => {
      window.removeEventListener('message', onReceiveMessage, false);
    }
  }, []);

  useEffect(() => {
    const { attributes } = props;
    const defaultAttributes = {
        allowFullScreen: false
      , frameBorder: 0
    };
    setIFrameAttributes(Object.assign(
        {}
      , defaultAttributes
      , attributes
    ));
  }, [props.attributes.srcDoc]);

  useEffect(() => {
    if (isFirstPostMessage.current) {
      isFirstPostMessage.current = false;
    }
    else {
      if (typeof props.postMessageData !== 'undefined' && props.postMessage !== null) {
        // send a message if postMessageData changed
        sendMessage(props.postMessageData);
      }
    }
  }, [props.postMessageData]);

  useEffect(() => {
    if (props.shouldPrint) {
      iFrameElement.contentWindow.print();
    }
  }, [props.shouldPrint]);

  const onReceiveMessage = (event) => {
    const { handleReceiveMessage } = props;
    if (handleReceiveMessage) {
      handleReceiveMessage(event);
    }
  };

  const serializePostMessageData = (data) => {
    // Rely on the browser's built-in structured clone algorithm for serialization of the
    // message as described in
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    if (!props.serializeMessage) {
      return data;
    }

    // To be on the safe side we can also ignore the browser's built-in serialization feature
    // and serialize the data manually.
    if (typeof data === 'object') {
      return JSON.stringify(data);
    } else if (typeof data === 'string') {
      return data;
    } else {
      return `${data}`;
    }
  };

  const sendMessage = (postMessageData) => {
    // Using postMessage data from props will result in a subtle but deadly bug,
    // where old data from props is being sent instead of new postMessageData.
    // This is because data sent from componentWillReceiveProps is not yet in props but only in nextProps.
    const { targetOrigin } = props;
    const serializedData = serializePostMessageData(postMessageData);
    iFrameElement.contentWindow.postMessage(serializedData, targetOrigin);
  };

  return (
    <iframe
      ref={el => {
        setIFrameElement(el);
      }}
      {...iFrameAttributes}
      style={props.iframeStyle}
    ></iframe>
  );
};

ArticleIframe.defaultProps = {
    serializeMessage: true
  , targetOrigin: '*'
  , postMessageData: ''
};

ArticleIframe.propTypes = {
  // Iframe Attributes
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#Attributes
  // React Supported Attributes
  // https://facebook.github.io/react/docs/dom-elements.html#all-supported-html-attributes
  // Note: attributes are camelCase, not all lowercase as usually defined.
  attributes: PropTypes.shape({
      allowFullScreen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    , frameBorder: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    , height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    , name: PropTypes.string
    , scrolling: PropTypes.string
    // https://www.html5rocks.com/en/tutorials/security/sandboxed-iframes/
    , sandbox: PropTypes.string
    , srcDoc: PropTypes.string
    , src: PropTypes.string
    , width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    , shouldPrint: PropTypes.bool
    , iframeStyle: PropTypes.object
  })

  // Callback function called when iFrame sends the parent window a message.
  , handleReceiveMessage: PropTypes.func

  // You can pass it anything you want, we'll serialize to a string
  // preferablly use a simple string message or an object.
  // If you use an object, you need to follow the same naming convention
  // in the iframe so you can parse it accordingly.
  , postMessageData: PropTypes.any.isRequired

  // Enable use of the browser's built-in structured clone algorithm for serialization
  // by settings this to `false`. 
  // Default is `true`, using our built in logic for serializing everything to a string.
  , serializeMessage: PropTypes.bool

  // Always provide a specific targetOrigin, not *, if you know where the other window's 
  // document should be located. Failing to provide a specific target discloses the data 
  // you send to any interested malicious site.
  , targetOrigin: PropTypes.string
};