import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  normalContainer: {
    background: theme.root.background
  }
  , appBar: {
      background: `linear-gradient(${theme.toolbar.backgroundStartGradient}, ${theme.toolbar.backgroundEndGradient})`
    , color: theme.toolbar.textColor
  }
  , iconButton:{
      color: theme.toolbar.textColor
    , outline: 'none !important'
  }
  , toolBarImg: {
      filter: theme.toolbar.iconFilter
    , backgroundColor:'rgba(0, 0, 0, 0)'
  }
  , flexibleSpace: {
    flexGrow: 1,
  }
  , pageContainer: {
      display: 'flex'
    , flexDirection: 'row'
    , justifyContent: 'center'
    , alignItems: 'center'
    , overflowX: 'hidden'
    , overflowY: 'hidden'
    , padding: '12px'
    , userSelect: 'none'
  }
  , leftPageContainer: {
      position: 'relative'
    , float: 'left'
  }
  , rightPageContainer: {
      position: 'relative'
    , float: 'right'
  }
  , pubLoaderContainer: {
      textAlign: 'center'
    , color: theme.palette.primary.main
  }
  , pdfPage: {
      position: 'relative'
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
  }
  , pagePreviewImage: {
      position: 'relative'
    , backgroundColor: '#ffffff'
    , boxShadow: '5px 4px 5px rgba(0, 0, 0, 0.2)'
  }
  , hiddenPagePreviewImage: {
      visibility: 'hidden'
    , width: '0px'
    , height: '0px'
    , position: 'absolute'
  }
  , progressBar: {
      marginLeft:'20px'
    , width:'calc(100% - 40px)'
  }
  , previewPickerContainer: {
      position: 'absolute'
    , pointerEvents: 'none'
    , zIndex: 1
    , width: '100%'
    , height: '100px'
    , marginTop: '50%'
    , minWidth: '0px'
  }
  , previewPrevButton: {
      float: 'left'
    , pointerEvents: 'auto'
    , minWidth: '20px'
    , height: '100%'
    , transform: 'translateX(-33%)'
    , msTransform: 'translateX(-33%)'
    , outline: 'none !important'
    , color: '#ffffff'
    , backgroundColor: alpha(theme.palette.primary.main, 0.35)
    , transition: '0.5s'
    , '&:hover': { 
        backgroundColor: alpha(theme.palette.primary.main, 0.50) 
      , transition: '0s'
    }
  }
  , previewNextButton: {
      float: 'right'
    , pointerEvents: 'auto'
    , minWidth: '20px'
    , height: '100%'
    , transform: 'translateX(33%)'
    , msTransform: 'translateX(33%)'
    , outline: 'none !important'
    , color: '#ffffff'
    , backgroundColor: alpha(theme.palette.primary.main, 0.35)
    , transition: '0.5s'
    , '&:hover': { 
        backgroundColor: alpha(theme.palette.primary.main, 0.50) 
      , transition: '0s'
    }
  }
}));