import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { SNRoutes } from './routes';
import { ThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Workbox } from 'workbox-window';
import 'bootstrap/dist/css/bootstrap.min.css';
import('react-pdf').then((pdfjs) => 
  pdfjs.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.pdfjs.version}/pdf.worker.js`
);
import { Amplify, Auth, Analytics} from 'aws-amplify';
import awsconfig from './aws-exports';
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';

import store from './redux/store.js';
import AccountProvider from './providers/AccountProvider.js';
import UserPrefsSingleton from './api/singletons/UserPrefsSingleton.js';
import IPValidatorSingleton from './api/singletons/IPValidatorSingleton.js';

import './styles/index.css';
import { mainTheme } from './branding/snTheme.js';
// import { mainTheme } from './branding/miradoDevTheme.js';

import messages_en from './translations/en.json';
import messages_de from './translations/de.json';
const messages = {
  'en': messages_en
, 'de': messages_de
};

const setupLocale = () => {
  const pendingLocale = navigator.language.split(/[-_]/)[0];
  const currentLocale = messages.hasOwnProperty(pendingLocale) ? pendingLocale : 'de';
  UserPrefsSingleton.setCurrentlLocale(currentLocale);
};

const setupTheme = () => {
  document.documentElement.style.setProperty('--primary-color', mainTheme.palette.primary.main);
  document.documentElement.style.setProperty('--primary-color-light', mainTheme.palette.primary.light);
  document.body.style.setProperty('font-family', mainTheme.typography.fontFamily);
};

const setupServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const wb = new Workbox('/sw.js');

      navigator.serviceWorker.getRegistration()
        .then(swReg => {
          if (swReg) {
            console.log('serviceWorker is already registered');
          }
        });

      wb.register().then(reg => {
        console.log('serviceWorker registered');

        reg.onupdatefound = function (registrationEvent) {
          console.log('serviceWorker has an update available');

          let swReg = registrationEvent.target;
          if (swReg.installing) {
            console.log('serviceWorker update is installing');

            swReg.installing.onstatechange = function (stateChangeEvent) {
              if (stateChangeEvent.target.state === 'installed') {
                console.log('serviceWorker update finished installing');
                window.location.reload();
              }
            }
          }
        };
      });
    });
  }
};

const redirectToAppleAppStore = () => {
  window.location = mainTheme.textAssets.appleAppStoreUrl;
};

const redirectToAndroidPlayStore = () => {
  window.location = mainTheme.textAssets.androidPlayStoreUrl;
};

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <AccountProvider>
        <MobileView>
          {isIOS ? redirectToAppleAppStore() : null}
          {isAndroid ? redirectToAndroidPlayStore() : null}
        </MobileView>
        <BrowserView>
          <IntlProvider
            locale={UserPrefsSingleton.currentLocale()}
            messages={messages[UserPrefsSingleton.currentLocale()]}
          >
            <ThemeProvider theme={mainTheme}>
              <BrowserRouter>
                <SNRoutes />
              </BrowserRouter>
            </ThemeProvider>
          </IntlProvider>
        </BrowserView>
      </AccountProvider>
    </Provider>
    , document.getElementById('root')
  );
};

const killTheConsole = () => {
  window.console.log = function() {
    // console.error(mainTheme.textAssets.consoleMessage);
    window.console.log = function() { return false; }
  }
};

const main = () => {
  killTheConsole();

  Amplify.configure({
    Auth: {
      // mandatorySignIn: false,
      identityPoolId: 'eu-west-1:34383dbf-3d81-405b-8130-991d92462950',
      region: 'eu-west-1',
      userPoolId: awsconfig.aws_user_pools_id,
      userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
    }
  });

  Auth.configure({
    mandatorySignIn: false
  });
 
  const analyticsConfig = {

    AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: 'a62f9389a06e4c5fa25a36fb5a7867d5',
          // Amazon service region
          region: 'eu-west-1',
          mandatorySignIn: false,
    }
  }
  
  Analytics.configure(analyticsConfig)

  setupLocale();
  setupTheme();
  setupServiceWorker();
  IPValidatorSingleton.checkIsValidated();
  render();
};

main();