class EntitiesSingleton {

  constructor() {
    if (!EntitiesSingleton.instance) {
      EntitiesSingleton.instance = this;
      this.data = {};
    }
    return EntitiesSingleton.instance;
  }

  fetchCurrentEntities() {
    return new Promise((resolve, reject) => {
      fetch('https://snmps.mirado1.info/publist/currentWaEntities?username=sn@bulletinreader.com&password=sn00000')
      // fetch('http://23.23.111.217:8080/publist/currentWaEntities?username=sn@bulletinreader.com&password=sn00000')
        .then((response) => response.json())
        .then((responseJSON) => this._validateResponseJSON(responseJSON))
        .then((responseJSON) => this._cleanupResponseJSON(responseJSON))
        .then((responseJSON) => { this.data.lastGoodResponse = responseJSON; })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  fetchArchiveEntities() {
    return new Promise((resolve, reject) => {
      fetch('https://snmps.mirado1.info/publist/allWaEntities?username=sn@bulletinreader.com&password=sn00000')
        .then((response) => response.json())
        .then((responseJSON) => this._validateResponseJSON(responseJSON))
        .then((responseJSON) => this._cleanupResponseJSON(responseJSON))
        .then((responseJSON) => { this.data.lastGoodArchiveResponse = responseJSON; return responseJSON; })
        .then((responseJSON) => this._getPubListFromResponseJSON(responseJSON))
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  getCatPickerList() {
    return this._getCatPickerListFromResponseJSON(this.data.lastGoodResponse);
  }

  getShowcaseCarouselItemsForCat(cat) {
    let retVal = [];
    if ( typeof cat === 'undefined'
      || cat === null
      || typeof cat.entities === 'undefined'
      || cat.entities === null
    ) { return retVal; }
    
    // NOTE: Watch out for double-arrayed genres
    const entitiesList = Array.isArray(cat.entities[0]) ? cat.entities[0] : cat.entities;
    for (const someEntityID of entitiesList) {
      let someEntity = this.getEntityWithID(someEntityID);

      if (someEntity.entity_type === 'Category') {
        // NOTE: Watch out for double-arrayed genres
        const someEntitiesList = Array.isArray(someEntity.entities[0]) ? someEntity.entities[0] : someEntity.entities;
        if (someEntitiesList.length > 0) {
          const firstEntityID = someEntitiesList[0];
          someEntity = this.getEntityWithID(firstEntityID);
        }
      }

      if ( typeof someEntity === 'undefined' 
        || someEntity === null
        || Object.keys(someEntity).length === 0
      ) { continue; }

      retVal.push(someEntity);
    }

    return retVal;
  }

  getIndexOfCat(cat) {
    const catList = this.getCatPickerList();
    for (const someCat of catList) {
      if (someCat.id == cat.id) {
        return catList.indexOf(someCat);
      }
    }
    return -1;
  }

  getEntityWithID(entityID) {
    return this._getEntityWithIDFromResponseJSON(entityID, this.data.lastGoodResponse);
  }
  
  _getArchiveEntityWithID(entityID) {
    return this._getEntityWithIDFromResponseJSON(entityID, this.data.lastGoodArchiveResponse);
  }

  getArchiveListWithCat(cat) {
    let retVal = [];
    let entitiesList = [];
    if (cat.entity_type === 'Genre') {
      const matchingArchiveGenres = this._getGenreListFromResponseJSON(this.data.lastGoodArchiveResponse)
        .filter((someGenre) => { return someGenre.id == cat.id });
      if (matchingArchiveGenres.length > 0) {
        entitiesList = matchingArchiveGenres[0].entities;
      }
    }
    else {
      const matchingArchiveCats = this._getCatListFromResponseJSON(this.data.lastGoodArchiveResponse)
        .filter((someCat) => { return someCat.id == cat.id; });
      if (matchingArchiveCats.length > 0) {
        entitiesList = matchingArchiveCats[0].entities;
      }
    }

    for (const someEntityID of entitiesList) {
      const someEntity = this._getEntityWithIDFromResponseJSON(someEntityID, this.data.lastGoodArchiveResponse);
      if ( typeof someEntity !== 'undefined'
        && someEntity !== null
        && Object.keys(someEntity).length > 0
        && someEntity.entity_type !== 'Category'
      ) {
        retVal.push(someEntity);
      }
      if(someEntity.entity_type === 'Category'){
        // if (matchingGenreCats.length > 0) {
          const catGenreentitiesList = someEntity.entities;
          for (const gcEndityID  of catGenreentitiesList) {
            const gcEntity = this._getEntityWithIDFromResponseJSON(gcEndityID, this.data.lastGoodArchiveResponse);
              if ( typeof gcEntity !== 'undefined'
                && gcEntity !== null
                && Object.keys(gcEntity).length > 0  
              ) {
                retVal.push(gcEntity);
              }
          }
        // }
      }
    }
    return retVal;
  }

  getArchlistWithContainer(con) {

    let retVal = {};
    let entitiesList = [];
    let retPubs = [];
    let genPubs = [];

    if (con.entity_type === 'Genre') {
      const matchingArchiveGenres = this._getGenreListFromResponseJSON(this.data.lastGoodArchiveResponse)
        .filter((someGenre) => { return someGenre.id == con.id });
      if (matchingArchiveGenres.length > 0) {
        entitiesList = matchingArchiveGenres[0].entities;
      }

      for (const someEntityID of entitiesList) {
        const someEntity = this._getEntityWithIDFromResponseJSON(someEntityID, this.data.lastGoodArchiveResponse);
        if ( typeof someEntity !== 'undefined'
          && someEntity !== null
          && Object.keys(someEntity).length > 0
          && someEntity.entity_type !== 'Category'
        ) {
          genPubs.push(someEntity);
        }
        if(someEntity.entity_type === 'Category'){
            let catPubs = [];
            const catGenreentitiesList = someEntity.entities;
            for (const gcEndityID  of catGenreentitiesList) {
              const gcEntity = this._getEntityWithIDFromResponseJSON(gcEndityID, this.data.lastGoodArchiveResponse);
                if ( typeof gcEntity !== 'undefined'
                  && gcEntity !== null
                  && Object.keys(gcEntity).length > 0  
                ) {
                  catPubs.push(gcEntity);
                }
            }
            retVal[someEntity.id] = catPubs;
        }
      } 
      retVal['genPubs'] = genPubs;    
    }
    else {
      const matchingArchiveCats = this._getCatListFromResponseJSON(this.data.lastGoodArchiveResponse)
        .filter((someCon) => { return someCon.id == con.id; });
      if (matchingArchiveCats.length > 0) {
        entitiesList = matchingArchiveCats[0].entities;
      }
      for (const someEntityID of entitiesList) {
        const someEntity = this._getEntityWithIDFromResponseJSON(someEntityID, this.data.lastGoodArchiveResponse);
        if ( typeof someEntity !== 'undefined'
          && someEntity !== null
          && Object.keys(someEntity).length > 0
          && someEntity.entity_type !== 'Category'
        ) {
          retPubs.push(someEntity);
        }
      }
      retVal[con.name] = retPubs;
    }

    return retVal;
  }

  getCatFromEntityId(eid){
    let retVal = {}; 
    retVal = this._getEntityWithIDFromResponseJSON(eid, this.data.lastGoodArchiveResponse);
    return retVal;
  }


  _validateResponseJSON(responseJSON) {
    if (typeof responseJSON.login === 'undefined' 
    ||  responseJSON.login === null
    ||  responseJSON.login === false
    ) {
      throw new Error('Bad login');
    }
    
    return responseJSON;
  }

  _cleanupResponseJSON(responseJSON) {
    const stringifiedResponseJSON = JSON.stringify(responseJSON);
    const cleanedStringifiedResponseJSON = stringifiedResponseJSON.replace(/http\:\/\//g, 'https://');
    const cleanedResponseJSON = JSON.parse(cleanedStringifiedResponseJSON);
    return cleanedResponseJSON;
  }

  _getCatPickerListFromResponseJSON(responseJSON) {
    var retVal = [];
    if (typeof responseJSON === 'undefined' ||  responseJSON === null
    ||  typeof responseJSON.pickers === 'undefined' || responseJSON.pickers === null
    ||  responseJSON.pickers.length === 0
    ) {
      return retVal;
    }

    for (const someEntityID of responseJSON.pickers[0].entities) {
      for (const someGenre of responseJSON.genres) {
        if (someGenre.id == someEntityID) {
          if(someGenre.name == "Für Sie empfohlen"){
            var webAppEntity = someGenre;
            webAppEntity.image = "https://sn-data.s3.amazonaws.com/rubriken_icons/aktuelleAusgaben.png";
            webAppEntity.name = "Aktuelle Ausgaben";
            webAppEntity.description = "Aktuelle Ausgaben";
            retVal.push(webAppEntity);
          }
          else {
            retVal.push(someGenre);
          }
         
        }
      }
      for (const someCat of responseJSON.categories) {
        if (someCat.id == someEntityID) {
          retVal.push(someCat);
        }
      }
    }

    return retVal;
  }

  _getAllEntitiesListFromResponseJSON(responseJSON) {
    const pubList = this._getPubListFromResponseJSON(responseJSON);
    const genreList = this._getGenreListFromResponseJSON(responseJSON);
    const catList = this._getCatListFromResponseJSON(responseJSON);
    const retVal = pubList.concat(genreList, catList);
    return retVal;
  }

  _getCatListFromResponseJSON(responseJSON) {
    if (typeof responseJSON.categories === 'undefined' 
    ||  responseJSON.categories === null
    ||  responseJSON.categories.length === 0
    ) {
      return [];
    }

    return responseJSON.categories;
  }

  _getPubListFromResponseJSON(responseJSON) {
    if (typeof responseJSON.publications === 'undefined' 
    ||  responseJSON.publications === null
    ||  responseJSON.publications.length === 0
    ) {
      return [];
    }

    return responseJSON.publications;
  }

  _getGenreListFromResponseJSON(responseJSON) {
    if (typeof responseJSON.genres === 'undefined' 
    ||  responseJSON.genres === null
    ||  responseJSON.genres.length === 0
    ) {
      return [];
    }

    return responseJSON.genres;
  }

  _getEntityWithIDFromResponseJSON(entityID, responseJSON) {
    const allEntities = this._getAllEntitiesListFromResponseJSON(responseJSON);
    for (const someEntity of allEntities) {
      if (someEntity.id == entityID) {

        return someEntity;
      }
    }

    // console.log(`could not find entity with ID = ${entityID}`);
    return {};
  }
}

const instance = new EntitiesSingleton();
Object.freeze(instance);

export default instance;