import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
      height: '100%'
    , overflow: 'hidden'
    , background: theme.root.background
  }
  , catPickerLoaderContainer: {
      textAlign: 'center'
    , paddingTop: '10px'
  }
  , tabContentContainer: {
      height: '100%'
    , overflowX: 'hidden'
    , overflowY: 'auto'
  }
  , appBar: {
      zIndex: theme.zIndex.drawer + 1
    , background: `linear-gradient(${theme.toolbar.backgroundStartGradient}, ${theme.toolbar.backgroundEndGradient})`
    , color: theme.toolbar.textColor
  }
  , tab: {
    outline: 'none !important'
  }
  , selectedTab: {
      outline: 'none !important'
    , background: 'radial-gradient(circle, #ffffff 0%, rgba(255,255,255,0) 85%)'
  }
  , tabIndicator: {
    height: '3px'
  }
}));