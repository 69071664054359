import React, { useRef, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import {Img, useImage} from 'react-image';
import ScaleLoader from 'react-spinners/ScaleLoader';
import AliceCarousel from 'react-alice-carousel';

import { useStyles } from '../styles/CatPickerStyles';
export default function CatPickerComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const shouldCancelCatPickerItemOnClick = useRef(false);
  const isMouseClickHappening = useRef(false);
  // const catPickerContainer = createRef();
  const catPickerContainer = useRef(null);
  const shouldLockWheel = useRef(false);
  // const carousel = createRef();
  const carousel = useRef(null);
  const shouldIgnoreWheel = useRef(false);
  const wheelQuietTimeout = useRef(null);
  const previousWheelDelta = useRef(0);
  const preservedStageStyleTransform = useRef('');

  useEffect(() => {
    const stageStyleObserver = new MutationObserver(handleStageStyleMutations);
    stageStyleObserver.observe(getStageElement(), { attributes : true, attributeFilter : ['style'] } );

    document.addEventListener('wheel', handleDocumentWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleDocumentWheel);
      stageStyleObserver.disconnect();
    };
  }, []);

  const getStageElement = () => {
    let retVal = null;
    const elements = document.getElementsByClassName('alice-carousel__stage');
    if (elements.length > 0) {
      retVal = elements[0];
    }
    return retVal;
  };

  const catPickerLoader = () => {
    return (
      <div className={classes.catLoaderContainer}>
        <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
      </div>
    );
  };

  const catPickerItems = () => {
    return props.sourceCatList.map((someCat, index) => (
      <Button 
        key={index}
        className={(someCat.id === props.selectedCat.id) ? classes.selectedCatButton : classes.catButton}
        style={customStyleForCat(someCat)}
        variant='contained'
        onDragStart={handleCatPickerItemOnDragStart}
        onMouseDown={handleCatPickerItemOnMouseDown}
        onMouseMove={handleCatPickerItemOnMouseMove}
        onMouseUp={handleCatPickerItemOnMouseUp.bind(this, someCat)}
      >
        {(typeof someCat.image === 'undefined' || someCat.image === null)
          ? <div>{someCat.name}</div>
          : <Img 
              className={classes.catImg} 
              src={someCat.image}
              loader={catPickerLoader()}
              unloader={<Typography variant='h6'>{someCat.name}</Typography>}
            ></Img>
        }
      </Button>
    ));
  };

  const customStyleForCat = (cat) => {
    let retVal = {};
    switch (cat.name) {
      case 'tele':
        retVal.background = 'rgb(251, 30, 51)';
        break;
      
      case 'Salzburger Woche':
        retVal.background = 'rgb(244, 100, 64)';
        break;
      
      case 'Hallo Nachbar!':
        retVal.background = 'rgb(206, 234, 251)';
        break;
      
      case 'Fenster Magazin':
        retVal.background = 'rgb(255, 255, 255)';
        break;
      
      default:
        break;
    }
    return retVal;
  };

  const carouselResponsiveLayout = () => {
    let retVal = {};
    let runningWidth = 0;
    let runningIndex = 0;
    const maxThumbWidth = parseInt(theme.catPicker.maxThumbWidth.replace(/px/, ''));
    props.sourceCatList.forEach((someCat) => {
      retVal[runningWidth] = { items: runningIndex };
      runningWidth += maxThumbWidth + 10; // NOTE: 10 = 5px left margin + 5px right margin
      runningIndex++;
    });
    retVal[runningWidth] = { items: runningIndex };
    return retVal;
  };

  const preserveStageStyle = () => {
    const stageElement = getStageElement();
    preservedStageStyleTransform.current = stageElement.style.transform;
  };

  const restoreStageStyle = () => {
    const stageElement = getStageElement();
    stageElement.style.transform = preservedStageStyleTransform.current;
    preservedStageStyleTransform.current = '';
  };

  const handleStageStyleMutations = (mutations) => {
    const stageElement = document.getElementsByClassName('alice-carousel__stage')[0];
    if (preservedStageStyleTransform.current.length > 0 
    &&  preservedStageStyleTransform.current !== stageElement.style.transform
    ) {
      restoreStageStyle();
    }
  };

  const handleCatPickerItemOnDragStart = (event) => {
    event.preventDefault();
  };

  const handleCatPickerItemOnMouseDown = () => {
    shouldCancelCatPickerItemOnClick.current = false;
    isMouseClickHappening.current = true;
    // console.log(`handleCatPickerItemOnMouseDown enabled shouldCancelCatPickerItemOnClick`);

  };

  const handleCatPickerItemOnMouseMove = () => {
    // Don't cancel the if click in progress
    //
    if(!isMouseClickHappening.current){
      shouldCancelCatPickerItemOnClick.current = true;
      // console.log(`handleCatPickerItemOnMouseMove cancelled shouldCancelCatPickerItemOnClick`);
    }
  };

  const handleCatPickerItemOnMouseUp = (clickedCat) => {
    // console.log(`handleCatPickerItemOnMouseUp cat picker mouse up`);
    isMouseClickHappening.current = false;
    if (!shouldCancelCatPickerItemOnClick.current) {
      preserveStageStyle();
      // console.log(`handleCatPickerItemOnMouseUp call cat button click`);
      props.onCatButtonClick(clickedCat);
    }
  };

  const handleCatPickerContainerOnWheel = (event) => {
    const delta = (event.deltaY === 0) ? event.deltaX : event.deltaY;
    const didChangeDirections = (
         (delta > 0 && previousWheelDelta.current < 0) 
      || (delta < 0 && previousWheelDelta.current > 0)
    );
    if (didChangeDirections || Math.abs(delta - previousWheelDelta.current) > 10) {
      shouldIgnoreWheel.current = false;
      clearTimeout(wheelQuietTimeout.current);
    }
    previousWheelDelta.current = delta;
    // console.log(`handleCatPickerContainerOnWheel delta: ${delta}`);

    if (shouldIgnoreWheel.current) {
      clearTimeout(wheelQuietTimeout.current);
      wheelQuietTimeout.current = setTimeout(() => {
        shouldIgnoreWheel.current = false;
        // console.log(`handleCatPickerContainerOnWheel wheelQuietTimeout event`);
      }, 50);
      // console.log(`handleCatPickerContainerOnWheel ignoring current event`);
      return;
    }

    shouldIgnoreWheel.current = true;
    wheelQuietTimeout.current = setTimeout(() => {
      // console.log(`handleCatPickerContainerOnWheel wheelQuietTimeout event 2`);
      shouldIgnoreWheel.current = false;
    }, 50);

    if (delta > 0) {
      // console.log(`handleCatPickerContainerOnWheel sliding prev: ${delta}`);
      carousel.current.slidePrev();
    }
    else if (delta < 0) {
      // console.log(`handleCatPickerContainerOnWheel sliding next: ${delta}`);
      carousel.current.slideNext();
    }
  };

  const handleDocumentWheel = (event) => {
    if (shouldLockWheel.current) {
      event.preventDefault();
    }
  };

  const handleCatPickerContainerOnMouseEnter = () => {
    shouldLockWheel.current = true;
  };

  const handleCatPickerContainerOnMouseLeave = () => {
    shouldLockWheel.current = false;
  };

  return (
    <div
      ref={catPickerContainer}
      className={classes.catPickerContainer}
      onWheel={handleCatPickerContainerOnWheel}
      onMouseEnter={handleCatPickerContainerOnMouseEnter}
      onMouseLeave={handleCatPickerContainerOnMouseLeave}
    >
      <AliceCarousel
        ref={carousel}
        // mouseTrackingEnabled
        animationDuration={200}
        disableDotsControls={true}
        disableButtonsControls={true}
        mouseTracking={true}
        infinite={true}
        // buttonsDisabled
        // preservePosition
        responsive={carouselResponsiveLayout()}
        startIndex={props.startIndex}
      >
        {catPickerItems()}
      </AliceCarousel>
    </div>
  );
};

CatPickerComponent.propTypes = {
    sourceCatList: PropTypes.array.isRequired
  , selectedCat: PropTypes.any.isRequired
  , onCatButtonClick: PropTypes.func.isRequired
  , startIndex: PropTypes.number
};

CatPickerComponent.defaultProps = {
  startIndex: 0
};