import * as React from "react";

const SvgKonto = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      d="M250 0C111.93 0 0 111.93 0 250s111.93 250 250 250 250-111.93 250-250S388.07 0 250 0Zm-60.83 98.59q23.72-23.72 61-23.72t61 23.72q23.72 23.72 23.71 61t-23.71 61q-23.73 23.72-61 23.71t-61-23.71q-23.71-23.73-23.72-61t23.72-61ZM402 402a215.15 215.15 0 0 1-332.4-34.95q1.2-18 10.55-31.31a68.86 68.86 0 0 1 27.67-23.16q37.85-16.94 72.57-25.41a293.17 293.17 0 0 1 69.74-8.47 281.3 281.3 0 0 1 69.46 8.75 482.85 482.85 0 0 1 72.28 25.13 68 68 0 0 1 28.23 23.16q9.25 13.13 10.52 30.88A215.76 215.76 0 0 1 402 402Z"
      style={{
        fill: "#0358a9",
      }}
    />
  </svg>
);

export default SvgKonto;
