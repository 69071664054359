import React, { useState, useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { TextField, Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { useStyles } from '../styles/AccountStyles';
import * as OV from '../api/OVConstants.js';
import { AccountContext } from '../providers/AccountProvider.js';
import { 
    accountStatusSelector
  , accountEmailSelector
  , accountSNPlusSelector
  , accountSNPlusTimeStampSelector
} from '../redux/selectors/AccountSelectors.js';
import { Link } from 'react-router-dom';


export default function AccountComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { loginWithCredentials, logout } = useContext(AccountContext);
  const isFirstAccountStatus = useRef(true);
  const accountStatus = useSelector(accountStatusSelector);
  const accountEmail = useSelector(accountEmailSelector);
  const accountSNPlus = useSelector(accountSNPlusSelector);
  const accountSNPlusTimeStamp = useSelector(accountSNPlusTimeStampSelector);
  const [isShowingLoginAlert, setIsShowingLoginAlert] = useState(false);
  const [isShowingErrorAlert, setIsShowingErrorAlert] = useState(false);
  const isLongVersion = props.isLongVersion;

  useEffect(() => {
    if (isFirstAccountStatus.current) {
      isFirstAccountStatus.current = false;
      return;
    }

    switch(accountStatus) {
      case OV.STATUS_SUCCESS: {
        setIsShowingLoginAlert(true);
        break;
      }
      case OV.STATUS_ERROR: {
        setIsShowingErrorAlert(true);
        break;
      }
    }
  }, [accountStatus]);

  const loginControls = () => (
    <div>
       {isLongVersion &&  
          <React.Fragment>
            <div className={classes.taglineContainer}>
              <FormattedMessage id='special.tagline' />
            </div>
          </React.Fragment>
        }

      <form 
        // onSubmit={onSave}
        // onKeyPress={event => {
        //   handleKeypress(event);
        // }}
      >
        <div className={classes.textFieldLabel}>
          <FormattedMessage id='account.username' />
        </div>
        <TextField
          id='accountTextField'
          className={classes.textField}
          InputProps={{
            className: classes.input
          }}
          // label={<FormattedMessage id='account.username' />}
          variant='outlined'
          onKeyPress={event => {
            handleKeypress(event);
          }}
        ></TextField>
        <div className={classes.textFieldLabel}>
          <FormattedMessage id='account.password' />
        </div>
        <TextField
          id='passwordTextField'
          className={classes.textField}
          InputProps={{
            className: classes.input
          }}
          // label={<FormattedMessage id='account.password' />}
          variant='outlined'
          type='password'
          onKeyPress={event => {
            handleKeypress(event);
          }}
        ></TextField>

        <Button
          className={classes.loginButton}
          onClick={handleLoginOnClick}
          variant='contained'
          color='primary'>
          <FormattedMessage id='account.login.button' />
        </Button>
        <p></p>
        {isLongVersion &&
          <React.Fragment >
            <div>
              <a href='https://www.sn.at/#passwordReset' target="_blank" rel="noreferrer noopener">{intl.formatMessage({ id:'account.join.now' })}</a>
            </div>        
            <div>
              <a href='https://www.sn.at/#register' target="_blank" rel="noreferrer noopener">{intl.formatMessage({ id:'account.forgot.password' })}</a>
            </div>   
          </React.Fragment>
        }
     
      </form>
    </div>
  );

  const waitingControls = () => (
    <div className={classes.loaderContainer}>
      <ScaleLoader color={`${theme.palette.primary.main}`}></ScaleLoader>
    </div>
  );

  const statusControls = () => (
    <div className={classes.statusContainer}>
      <b><FormattedMessage id='account.username' />:</b> {`${accountEmail}`}<br/>
      <b><FormattedMessage id='account.premium.title' />:</b> {renderSNPlusStatus()}<br/>
      <br/>
      <Button
          className={classes.loginButton}
          onClick={handleLogoutOnClick}
          variant='contained'
          color='primary'
        >
          <FormattedMessage id='account.logout.button' />
        </Button>
    </div>
  );

  const renderSNPlusStatus = () => {
    let isSNPlusEnabled = false;
    if ( `${accountSNPlus}` === '1'
      || (`${accountSNPlus}` == '2' && ((Date.now() / 1000) < accountSNPlusTimeStamp))
    ) {
      isSNPlusEnabled = true;
    }

    if (isSNPlusEnabled) {
      return <FormattedMessage id='account.premium.enabled' />;
    }
    else {
      return <FormattedMessage id='account.premium.disabled' />;
    }
  };

  const handleLoginOnClick = (event) => {
    event.preventDefault();
    const accountTextField = document.getElementById('accountTextField');
    const passwordTextField = document.getElementById('passwordTextField');
    loginWithCredentials(accountTextField.value, passwordTextField.value);
  };

  const onSave = (event) => {
    event.preventDefault();
    const accountTextField = document.getElementById('accountTextField');
    const passwordTextField = document.getElementById('passwordTextField');
    loginWithCredentials(accountTextField.value, passwordTextField.value);
  };

  const handleLogoutOnClick = () => {
    logout();
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      onSave(e);
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsShowingLoginAlert(false);
    setIsShowingErrorAlert(false);
  };

  return (
    <div>
      {(accountStatus === OV.STATUS_PROMPT || accountStatus === OV.STATUS_ERROR) ? loginControls() : <></>}
      {(accountStatus === OV.STATUS_BUSY) ? waitingControls() : <></>}
      {(accountStatus === OV.STATUS_SUCCESS) ? statusControls() : <></>}

      <Snackbar open={isShowingLoginAlert} autoHideDuration={5000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          <FormattedMessage id='account.login.success' />
        </Alert>
      </Snackbar>
      <Snackbar open={isShowingErrorAlert} autoHideDuration={5000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity='error'>
          <FormattedMessage id='account.login.error' />
        </Alert>
      </Snackbar>
    </div>
  );
};

AccountComponent.propTypes = {
  isLongVersion: PropTypes.bool.isRequired
};