import { createTheme } from '@material-ui/core/styles';

const snBlue = '#0358a9';

export const mainTheme = createTheme({
  textAssets: {
      title: 'SN'
    , appleAppStoreUrl: 'https://apps.apple.com/us/app/salzburger-nachrichten/id904040687?ign-itsct=apps_box&ign-itscg=30200'
    , androidPlayStoreUrl: 'https://play.google.com/store/apps/details?id=air.com.salzburg.readerapp.android.sn'
//     , consoleMessage: `
//                   '                                                    
//                 '-sn+-'          .'       .+:'            '/:'         
//             '-+snnnnns+-'     '+n'..   -nsnnsn-'       '/snns/.        
//           '.+snnnnnnnnnnns+-' -s/':s:'-nnnnnnnnns/ -+ '/snnnnnns+.     
//         ./snnnnnnnnnnnnnnnnnsns-'nn. +snnnnnnnnnns +n/snnnnnnnnnnn+    
//       'snnnnnnnssnnnnnnnnnnnn+'-s/    ./snnnnnnnns +nsnnnnnnnnnnnns    
//       'nnnnnnnn.'-/nsnnnnnns:'+s.       'snnnnnnns +n.:nssnnnnnnnns    
//       'nnnnnnnn.    '-/snnn..s+'         snnnnnnns +n   '.snnnnnnns    
//       'nnnnnnnn.       /s/'/s-           snnnnnnns +n     snnnnnnns    
//       'nnnnnnnn-     'ns..nn'            snnnnnnns +n     snnnnnnns    
//       'nnnnnnnns+-' -s+':s:              snnnnnnns +n     snnnnnnns    
//       'nnnnnnnnnnnnss-'ns.               snnnnnnns +n     snnnnnnns    
//       'nnnnnnnnnnnnn'-sn/-'              snnnnnnns +n     snnnnnnns    
//       'nnnnnnnnnns:'+nnnnnsn/-           snnnnnnns +n     snnnnnnns    
//       '-/snnnnns'.snnnnnnnnnns           snnnnnnns +n     snnnnnnns    
//           '-sn/ /nnnnnnnnnnnnnn'         snnnnnnns +n     snnnnnnns    
//           'ns.'sssnnnnnnnnnnnnn'         snnnnnnns +n     snnnnnnns    
//           -s+':s/  '-+snnnnnnnn'         snnnnnnns +n     snnnnnnns    
//         +s-'ns.       /nnnnnnnn'         snnnnnnns +n     snnnnnnns    
//       .sn'-s+'        /nnnnnnnn'         snnnnnnns +n     snnnnnnns    
//       /s:'+n:         /nnnnnnnn'         snnnnnnns +n     snnnnnnns    
//     'nn..snnss/-'     /nnnnnnnn'         snnnnnnns +n     snnnnnnns    
//   :s/'/snnnnnnnss/-'  /nnnnnnnn'         snnnnnnns +n     snnnnnnns    
// '+s..nnnnnnnnnnnnnnsnsnnnnnnnnn'         snnnnnnns +n     snnnnnnns    
// -s+':ssnnnnnnnnnnnnnnnnnnnnnnn-          snnnnnnns +n     snnnnnnns'   
// :-'nn.'-/ssnnnnnnnnnnnnnnns+.            snnnnnnns +n     snnnnnnnnss/.
//   ':'     .:+snnnnnnnnnnn:'              snnnnnnns +n     snnnnnnnnnns.
//             './ssnnns+.                  ssssnnnns +n     .:+ssnnnns/' 
//                 .:+:'                   ..'.-/+s+ -+        '.:nsn.    
//                                                                 '      
// `
  }
  , palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: snBlue,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: snBlue,
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  }
  , typography: {
    fontFamily: ['lato', 'sans-serif'].join(',')
  }
  , root: {
    background: 'radial-gradient(circle, rgba(253,253,253,1) 25%, rgba(219,219,219,1) 100%)'
  }
  , toolbar: {
      textColor: snBlue
    , height: '48px'
    , backgroundStartGradient: 'rgb(219, 219, 219)'
    , backgroundEndGradient: 'rgb(253, 253, 253)'
      // filter generator: https://codepen.io/sosuke/pen/Pjoqqp
    , iconFilter: 'invert(79%) sepia(95%) saturate(1805%) hue-rotate(189deg) brightness(93%) contrast(98%)'
  }
  , catPicker: {
      maxThumbWidth: '190px'
    , maxThumbHeight: '65px'
  }
  , showcasePicker: {
      maxThumbWidth: '360px'
    , maxThumbHeight: '540px'
    , reflectionHeight: '100px'
  }
  , pagePicker: {
      maxThumbWidth: '120px'
    , maxThumbHeight: '180px'
  }
  , coverPage: {
      maxThumbWidth: '200px'
    , maxThumbHeight: '300px'
  }

});