export const STATUS_PROMPT = 'prompt';
export const STATUS_BUSY = 'busy';
export const STATUS_ERROR = 'error';
export const STATUS_SUCCESS = 'success';

// NOTE: "LS" = Local Storage
export const LS_REDUX = 'ls_redux';
export const LS_PUB = 'ls_pub';
export const LS_FONTSIZE = 'ls_fontsize';
export const LS_CAPTIONS = 'ls_captions';
export const LS_SINGLEPAGE = 'ls_singlepage';
export const LS_FITWIDTH = 'ls_fitwidth';
export const LS_LOCALE = 'ls_locale';
export const LS_ARCHIVE_TIME = 'ls_archive_time';
export const LS_EMAIL = 'ls_email';

// NOTE: "RP" = Router Path
export const RP_ROOT = '/';
export const RP_PUBVIEW = '/pubview';
export const RP_ARTICLE = '/article';
export const RP_SLIDESHOW = '/slideshow';