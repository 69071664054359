/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:34383dbf-3d81-405b-8130-991d92462950",
    "aws_cognito_region": "eu-west-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_mobile_analytics_app_id": "a62f9389a06e4c5fa25a36fb5a7867d5",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
