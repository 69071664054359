export const accountStatusSelector = state => state.account.status;

export const accountAccessTokenSelector = state => state.account.accessToken;

export const accountAccessExpirationSelector = state => state.account.accessExpiration;

export const accountRefreshTokenSelector = state => state.account.refreshToken;

export const accountEmailSelector = state => state.account.email;

export const accountSNPlusSelector = state => state.account.snPlus;

export const accountSNPlusTimeStampSelector = state => state.account.snPlusTS;